/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  Forbidden,
  MeterSurveyRequestModel,
  Unauthorized,
} from '../models';

export interface SurveysGetSurveyRequest {
    type: string;
}

export interface SurveysStoreSurveyRequest {
    type: string;
    meterSurveyRequestModel: MeterSurveyRequestModel;
}

/**
 * 
 */
export class SurveysApi extends runtime.BaseAPI {

    /**
     * Get survey for logged in user. Default type if not sent is \"meter\"
     * Get survey by type
     */
    async surveysGetSurveyRaw(requestParameters: SurveysGetSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeterSurveyRequestModel>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling surveysGetSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/survey/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get survey for logged in user. Default type if not sent is \"meter\"
     * Get survey by type
     */
    async surveysGetSurvey(requestParameters: SurveysGetSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeterSurveyRequestModel> {
        const response = await this.surveysGetSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Store survey based on type, default type if not sent is \"meter\"
     * Store survey by type
     */
    async surveysStoreSurveyRaw(requestParameters: SurveysStoreSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeterSurveyRequestModel>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling surveysStoreSurvey.');
        }

        if (requestParameters.meterSurveyRequestModel === null || requestParameters.meterSurveyRequestModel === undefined) {
            throw new runtime.RequiredError('meterSurveyRequestModel','Required parameter requestParameters.meterSurveyRequestModel was null or undefined when calling surveysStoreSurvey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/survey/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.meterSurveyRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Store survey based on type, default type if not sent is \"meter\"
     * Store survey by type
     */
    async surveysStoreSurvey(requestParameters: SurveysStoreSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeterSurveyRequestModel> {
        const response = await this.surveysStoreSurveyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
