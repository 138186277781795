/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  Forbidden,
  GridXHeatPumpDeviceModel,
  HeatPumpDeviceConfigurationModel,
  HeatPumpDeviceModel,
  HeatPumpUpdateBehindGcpFlagRequest,
  InternalServerError,
  NotFound,
  Unauthorized,
  UnprocessableEntity,
} from '../models';

export interface HeatPumpGetGridXHeatPumpRequest {
    configId: string;
}

export interface HeatPumpGetHeatPumpRequest {
    clearCache?: string;
}

export interface HeatPumpUpdateBehindGcpFlagOperationRequest {
    gatewayId: string;
    applianceId: string;
    heatPumpUpdateBehindGcpFlagRequest?: HeatPumpUpdateBehindGcpFlagRequest;
}

export interface HeatPumpVerifyHeatPumpRequest {
    configId: string;
}

/**
 * 
 */
export class HeatPumpApi extends runtime.BaseAPI {

    /**
     * Get heat pump appliance detail from GridX
     */
    async heatPumpGetGridXHeatPumpRaw(requestParameters: HeatPumpGetGridXHeatPumpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GridXHeatPumpDeviceModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling heatPumpGetGridXHeatPump.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/heatpump:read"]);
        }

        const response = await this.request({
            path: `/api/v1/heatpump/{configId}/gridx/appliance`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get heat pump appliance detail from GridX
     */
    async heatPumpGetGridXHeatPump(requestParameters: HeatPumpGetGridXHeatPumpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GridXHeatPumpDeviceModel> {
        const response = await this.heatPumpGetGridXHeatPumpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get heat pump devices
     */
    async heatPumpGetHeatPumpRaw(requestParameters: HeatPumpGetHeatPumpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HeatPumpDeviceModel>>> {
        const queryParameters: any = {};

        if (requestParameters.clearCache !== undefined) {
            queryParameters['clearCache'] = requestParameters.clearCache;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/heatpump:read"]);
        }

        const response = await this.request({
            path: `/api/v1/heatpump`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get heat pump devices
     */
    async heatPumpGetHeatPump(requestParameters: HeatPumpGetHeatPumpRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HeatPumpDeviceModel>> {
        const response = await this.heatPumpGetHeatPumpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the behindGCP flag of a heat pump appliance
     */
    async heatPumpUpdateBehindGcpFlagRaw(requestParameters: HeatPumpUpdateBehindGcpFlagOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HeatPumpDeviceConfigurationModel>> {
        if (requestParameters.gatewayId === null || requestParameters.gatewayId === undefined) {
            throw new runtime.RequiredError('gatewayId','Required parameter requestParameters.gatewayId was null or undefined when calling heatPumpUpdateBehindGcpFlag.');
        }

        if (requestParameters.applianceId === null || requestParameters.applianceId === undefined) {
            throw new runtime.RequiredError('applianceId','Required parameter requestParameters.applianceId was null or undefined when calling heatPumpUpdateBehindGcpFlag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/heatpump:write"]);
        }

        const response = await this.request({
            path: `/api/v1/heatpump/{gatewayId}/appliances/{applianceId}/behindGCP`.replace(`{${"gatewayId"}}`, encodeURIComponent(String(requestParameters.gatewayId))).replace(`{${"applianceId"}}`, encodeURIComponent(String(requestParameters.applianceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.heatPumpUpdateBehindGcpFlagRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update the behindGCP flag of a heat pump appliance
     */
    async heatPumpUpdateBehindGcpFlag(requestParameters: HeatPumpUpdateBehindGcpFlagOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HeatPumpDeviceConfigurationModel> {
        const response = await this.heatPumpUpdateBehindGcpFlagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set GridX heat pump appliance desired state to VERIFYING
     */
    async heatPumpVerifyHeatPumpRaw(requestParameters: HeatPumpVerifyHeatPumpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GridXHeatPumpDeviceModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling heatPumpVerifyHeatPump.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/heatpump:write"]);
        }

        const response = await this.request({
            path: `/api/v1/heatpump/{configId}/gridx/appliance/verify`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set GridX heat pump appliance desired state to VERIFYING
     */
    async heatPumpVerifyHeatPump(requestParameters: HeatPumpVerifyHeatPumpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GridXHeatPumpDeviceModel> {
        const response = await this.heatPumpVerifyHeatPumpRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
