import { ExtendedLocale } from '../enums';

const { CMS_URL_DEFAULT, CMS_CONTENT_TYPE } = process.env;

// prettier-ignore
const PARAMS = '?expandAll=true&resolveLinks=short&version=' + (CMS_CONTENT_TYPE || 'published');

export const CMS_BASE = CMS_URL_DEFAULT;
export const CMS_NODE_BASE = '/api/v1/HEMS-Dashboard/nodes/';
export const CMS_BASE_URL = CMS_BASE + CMS_NODE_BASE;
export const CMS_BASE_PARAMS = '/children' + PARAMS;

export const CMS_FAQ_UUID = '236b2739ec374ebd9e98dfc8e5b999cd';
export const CMS_TERMS_UUID = '35140c6cc8ed400f9b63e0d25c9eb12e';
export const CMS_SALES_UUID = '3b0e49e5ee2d4563a0c0ceb96cce5d21';
export const CMS_PRIVACY_UUID = 'f791a974cf5e4d51b8361e7e8fce9ea7';
export const CMS_CONTACT_UUID = 'acd2f97b880a4d659fcfaad5a16b5180';
export const CMS_SURVEYS_UUID = '4cd1f79a722642cebf83d78ae52563c1';
export const CMS_MARKETING_UUID = '17df745cb5154ebeb028fbdb4c17d0a8';
export const CMS_HIGHLIGHTS_UUID = 'd76bde6446e3477b88881104ef7af6a3';
export const CMS_TENANT_TERMS_UUID = '2229a5eb2d464156acd46aeeafe1bc60';
export const CMS_TENANT_PRIVACY_UUID = '35940cf80fde44479f42ec8e3615830b';
export const CMS_REFERRAL_TERMS_UUID = '765728b381b24b41a039b00c650185a4';
export const CMS_HEATING_INSIGHTS_UUID = '246912759ca547f4a6e0060e8348576b';
export const CMS_INCIDENT_MESSAGES_UUID = '3e20076a9cb54fbbabbeeee6734a5046';

// prettier-ignore
export const getCMSUrl = (uuid: string) => CMS_BASE_URL + uuid + CMS_BASE_PARAMS;

export const CMS_FAQ_URL = getCMSUrl(CMS_FAQ_UUID);
export const CMS_SALES_URL = getCMSUrl(CMS_SALES_UUID);
export const CMS_TERMS_URL = getCMSUrl(CMS_TERMS_UUID);
export const CMS_PRIVACY_URL = getCMSUrl(CMS_PRIVACY_UUID);
export const CMS_CONTACT_URL = getCMSUrl(CMS_CONTACT_UUID);
export const CMS_SURVEYS_URL = getCMSUrl(CMS_SURVEYS_UUID);
export const CMS_MARKETING_URL = getCMSUrl(CMS_MARKETING_UUID);
export const CMS_HIGHLIGHTS_URL = getCMSUrl(CMS_HIGHLIGHTS_UUID);
export const CMS_TENANT_TERMS_URL = getCMSUrl(CMS_TENANT_TERMS_UUID);
export const CMS_TENANT_PRIVACY_URL = getCMSUrl(CMS_TENANT_PRIVACY_UUID);
export const CMS_REFERRAL_TERMS_URL = getCMSUrl(CMS_REFERRAL_TERMS_UUID);
export const CMS_HEATING_INSIGHTS_URL = getCMSUrl(CMS_HEATING_INSIGHTS_UUID);
export const CMS_INCIDENT_MESSAGES_URL = getCMSUrl(CMS_INCIDENT_MESSAGES_UUID);

export const CMS_LANGS: Record<ExtendedLocale, string> = {
    EN: 'en',
    GB: 'en',
    DE: 'de',
    IT: 'it',
    SE: 'sv',
    HU: 'hu',
    PL: 'pl',
    NL: 'nl',
    HR: 'hr',
};
