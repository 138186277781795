import { EventsResponseModel } from '@swagger-sse/api';

import { LiveDataActionTypes, WebsocketMessageSubTypeEnum } from '@store/enums';
import { LiveDataEvent, LiveDataAction, HeatPumpTelemetry } from '@store/types';
import { store } from '@src/index';

const { HEAT_PUMP_STATE_CHANGE } = WebsocketMessageSubTypeEnum;

export class HeatPumpEvent implements LiveDataEvent {
    private eventData: EventsResponseModel;

    constructor(data: any) {
        this.eventData = data;
    }

    public getAction(): LiveDataAction {
        const {
            eventData: { data, messageSubType },
        } = this;
        const lastUpdated =
            store.getState().liveData.telemetries.heatpump.lastUpdated;
        let payload: Partial<HeatPumpTelemetry> = {};

        if (
            messageSubType === (HEAT_PUMP_STATE_CHANGE as any) &&
            (!lastUpdated ||
                (lastUpdated &&
                    new Date(lastUpdated) < new Date(data.lastUpdated)))
        ) {
            payload = {
                error: !data.online,
                lastUpdated: data.lastUpdated,
            };
        }

        return {
            type: LiveDataActionTypes.HEAT_PUMP_TELEMETRIES,
            payload,
        };
    }
}
