import { UnknownFunction } from '@eon-home/react-library';

export const createTimer = (
    type: 'interval' | 'timeout',
    timeout: number,
    callback: UnknownFunction,
): number =>
    window[type === 'interval' ? 'setInterval' : 'setTimeout'](
        callback,
        timeout,
    );

export const clearTimer = (timer: unknown): void => {
    if (typeof timer !== 'number') {
        return;
    }

    clearTimeout(timer);
    clearInterval(timer);
};
