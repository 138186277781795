import { Dispatch } from 'redux';

import * as ENV from '@config/env';

import { brand } from '@eon-home/react-library';

import { Configuration } from '@swagger-http';

import { store } from '@src/index';
import moment from 'moment';
import { isLocalhost } from './helpers';
import { endPolling, requestLogout } from '@store/actions';
import {
    TOKEN,
    LOCALE,
    REGION,
    SCOPES,
    LOGIN_URL,
    REFRESH_TOKEN,
    TOKEN_THRESHOLD,
    API_URL_DEFAULT,
} from '../constants';

export const getAccessToken = (): string =>
    ENV.accessToken ||
    store.getState().auth.token ||
    localStorage.getItem(TOKEN) ||
    '';

export const generateAccessTokenThreshold = (): number =>
    moment().add(40, 'minutes').unix();

export const setTokenInLocalStorage = (token: string, refreshToken: string) => {
    localStorage.setItem(TOKEN, token);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
    localStorage.setItem(
        TOKEN_THRESHOLD,
        generateAccessTokenThreshold().toString(),
    );
};

export const createRequestConfiguration = (
    basePath = API_URL_DEFAULT,
): Configuration => {
    const accessToken = getAccessToken();

    return new Configuration({
        basePath,
        ...(accessToken
            ? {
                  accessToken: `Bearer ${accessToken}`,
              }
            : {}),
    });
};

export const cleanUpLocalStorage = () => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(SCOPES);
    localStorage.removeItem(LOCALE);
    localStorage.removeItem(REGION);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(TOKEN_THRESHOLD);
};

export const goToLogin = (params: string = ''): void => {
    const port = brand.whitelabel ? 5200 : 8080;
    const url: string = isLocalhost
        ? `http://localhost:${port}/login`
        : LOGIN_URL;

    window.location.href = url + (params ? '?error=' + params : '');
};

export const logOut = (dispatch: Dispatch<any>, siteId: string): void => {
    const promise = endPolling(siteId, dispatch).catch((e) => e);

    if (promise) {
        promise.then(() => dispatch(requestLogout(goToLogin)));
    }
};
