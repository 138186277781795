export const CODE = 'code';
export const TOKEN = 'token';
export const DEBUG = 'debug';
export const ROLES = 'roles';
export const STATE = 'state';
export const ENODE_STATE = 'enode-state';
export const SCOPES = 'scopes';
export const REGION = 'region';
export const LOCALE = 'locale';
export const TARGET_ROUTE = 'target-route';
export const REFRESH_TOKEN = 'refreshToken';
export const TOKEN_THRESHOLD = 'tokenThreshold';
export const CONNECTION_TYPE = 'connectionType';
export const ACTIVATION_POPUP_SEEN = 'activation-popup-seen';
