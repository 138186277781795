/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Forbidden,
  NotFound,
  SiteModel,
  Unauthorized,
} from '../models';

export interface SitesStartActivePollingRequest {
    siteId: string;
}

export interface SitesStopActivePollingRequest {
    siteId: string;
}

/**
 * 
 */
export class SitesApi extends runtime.BaseAPI {

    /**
     * Getting list of all assigned sites to a customer
     * Get list of sites
     */
    async sitesGetSitesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SiteModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/sites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Getting list of all assigned sites to a customer
     * Get list of sites
     */
    async sitesGetSites(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SiteModel>> {
        const response = await this.sitesGetSitesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Start a session of active polling data for a certain site
     * Start active polling session
     */
    async sitesStartActivePollingRaw(requestParameters: SitesStartActivePollingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling sitesStartActivePolling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/sites/active-polling/{siteId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Start a session of active polling data for a certain site
     * Start active polling session
     */
    async sitesStartActivePolling(requestParameters: SitesStartActivePollingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sitesStartActivePollingRaw(requestParameters, initOverrides);
    }

    /**
     * Stop a session of active polling data for a certain site
     * Stop active polling session
     */
    async sitesStopActivePollingRaw(requestParameters: SitesStopActivePollingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling sitesStopActivePolling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/sites/active-polling/{siteId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Stop a session of active polling data for a certain site
     * Stop active polling session
     */
    async sitesStopActivePolling(requestParameters: SitesStopActivePollingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sitesStopActivePollingRaw(requestParameters, initOverrides);
    }

}
