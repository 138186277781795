import { CMSDataTypes, CMSActionTypes } from '@store/enums';
import {
    CMSState,
    CMSAction,
    CMSStateItem,
    CMSContentState,
} from '@store/types';

const initialStateItem: CMSStateItem = {
    data: [],
    error: false,
    loading: true,
};

const cmsContentState: CMSContentState = Object.values(CMSDataTypes).reduce(
    (result: CMSState, key: string) => ({
        ...result,
        [key]: { ...initialStateItem },
    }),
    {} as CMSContentState,
);

export const initialState: CMSState = { ...cmsContentState, termsLinks: [] };

export default function reducer(
    state: CMSState = initialState,
    action: CMSAction,
): CMSState {
    switch (action.type) {
        case CMSActionTypes.ERROR:
        case CMSActionTypes.LOADING:
        case CMSActionTypes.SET_DATA:
        case CMSActionTypes.SET_TERMS_LINKS:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
}
