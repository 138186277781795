export enum CMSDataTypes {
    FAQ = 'faq',
    CONTACT = 'contact',
    TERMS = 'terms',
    SALES = 'sales',
    PRIVACY = 'privacy',
    SURVEYS = 'surveys',
    MARKETING = 'marketing',
    HIGHLIGHTS = 'highlights',
    ITALIAN_TERMS = 'italian-terms',
    REFERRAL_TERMS = 'referral-terms',
    ITALIAN_PRIVACY = 'italian-privacy',
    HEATING_INSIGHTS = 'heatingInsights',
    INCIDENT_MESSAGES = 'incidentMessages',
}

export const enum CMSActionTypes {
    ERROR = 'CMS_ERROR',
    LOADING = 'CMS_LOADING',
    SET_DATA = 'CMS_SET_DATA',
    SET_TERMS_LINKS = 'SET_TERMS_LINKS',
}
