export const enum GridStatus {
    EXPORTING = 'EXPORTING',
    IMPORTING = 'IMPORTING',
    BALANCED = 'BALANCED',
}

export const enum AggregatedDataActionTypes {
    FETCH = 'AGGREGATED_DATA_FETCH',
    ERROR = 'AGGREGATED_DATA_ERROR',
    LOADING = 'AGGREGATED_DATA_LOADING',
    SET_DATE = 'AGGREGATED_DATA_SET_DATE',
    SET_RESOLUTION = 'AGGREGATED_DATA_SET_RESOLUTION',
    SET_SLIDER_VALUE = 'SET_SLIDER_VALUE',
    SET_SLIDER_TOUCHED = 'SET_SLIDER_TOUCHED',
    SET_SLIDER_SCROLL_POSITION = 'SET_SLIDER_SCROLL_POSITION',
}
