import { Store, Action, Dispatch, AnyAction, Middleware } from 'redux';

import { ResponseError } from '@swagger-http';

import { RootState } from '@store/types';
import { getNewToken } from '@store/actions';
import { Moment, goToLogin, handleError } from '@tools/utils';

let refreshingToken = false;

export const auth: Middleware =
    (store: Store<RootState>) =>
    (next: Dispatch<AnyAction>) =>
    async <A extends Action>(action: A) => {
        if (typeof action !== 'function') {
            return next(action);
        }

        const { threshold, token } = store.getState().auth;
        const tokenExpired = Moment().unix() > threshold;

        if (tokenExpired && token && !refreshingToken) {
            refreshingToken = true;

            await getNewToken(store)
                .catch(async (e: ResponseError) => {
                    await handleError(e, 'Error when getting a new token:');

                    goToLogin();
                })
                .finally(() => {
                    refreshingToken = false;
                });

            return next(action);
        }

        return next(action);
    };
