import {
    EventsResponseModel,
    EvseChargingSessionStateChangeData,
} from '@swagger-sse/api';

import { store } from '@src/index';
import { LiveDataActionTypes } from '@store/enums';
import { LiveDataEvent, LiveDataAction, WallboxTelemetry } from '@store/types';

const {
    StationPresence,
    EvseChargingSessionStateChange,
    EvseChargingSessionStateFaulted,
} = EventsResponseModel.MessageSubTypeEnum;

// prettier-ignore
const { Charging, Finishing, StopFailed, StartFailed, StationInUse } = EvseChargingSessionStateChangeData.StatusEnum;

export class WallboxEvent implements LiveDataEvent {
    private eventData: EventsResponseModel;

    constructor(data: any) {
        this.eventData = data;
    }

    public getAction(): LiveDataAction {
        const {
            eventData,
            eventData: { data, creationTime, messageSubType },
        } = this;

        const payload: Partial<WallboxTelemetry> = {};
        const state = store.getState();

        // prettier-ignore
        const { status, sessionStartTime } = state.liveData.telemetries.emobility.wallbox;

        if (messageSubType === EvseChargingSessionStateChange) {
            if (
                !sessionStartTime ||
                (creationTime && sessionStartTime <= creationTime)
            ) {
                payload.status = data.status;
                payload.evStateLoading = false;
                payload.eventSessionId = data.chargingSessionID;

                // Always set sessiontStartTime on the first event
                if (!sessionStartTime) {
                    payload.sessionStartTime = eventData.creationTime;
                }

                if (
                    data.status !== StopFailed &&
                    data.status !== 'SuspendedEV'
                ) {
                    payload.sessionStartTime = eventData.creationTime;
                }

                if (data.status === Finishing) {
                    payload.isFinishing = true;
                }

                if (data.status !== StopFailed && data.status !== StartFailed) {
                    payload.commandError = false;
                }

                if (
                    data.status === Charging ||
                    data.status === StationInUse ||
                    data.status === 'SuspendedEV'
                ) {
                    payload.status = Charging;
                    payload.isWallboxCharging = true;
                } else {
                    payload.isWallboxCharging = false;
                }
            }
        }

        if (messageSubType === StationPresence) {
            payload.loading = false;
            payload.isWallboxOnline = data.online;
            payload.eventSessionId = data.chargingSessionID;

            if (!status) {
                payload.commandError = false;
                payload.isWallboxCharging = data.charging;
                payload.evStateLoading = false;

                if (payload.isWallboxCharging) {
                    payload.status = Charging;
                }
            }
        }

        if (messageSubType === EvseChargingSessionStateFaulted) {
            payload.commandError = false;
            payload.status = data.status;
            payload.evStateLoading = false;
        }

        return {
            type: LiveDataActionTypes.WALLBOX_TELEMETRIES,
            payload,
        };
    }
}
