import {
    HvacDeviceModelTypeEnum,
    SmartHomeDeviceModelTypeEnum,
} from '@swagger-http';

export const enum SmartHomeAction {
    GET_ALL_DEVICES = 'GET_ALL_DEVICES',
    SET_FILTER = 'SET_FILTER',
    RESET_FILTER = 'RESET_FILTER',
    SET_BRANCHES = 'SET_BRANCHES',
    REFRESH_DEVICES = 'REFRESH_DEVICES',
    SET_VENDORS_STATE = 'SET_VENDORS_STATE',
    SET_LOADING = 'SET_LOADING',
    SET_DEVICE_ERROR = 'SET_DEVICE_ERROR',
    SET_DEVICE_LOADING = 'SET_DEVICE_LOADING',
}

export const enum SmartHomeDevice {
    GATEWAY = 'gateway',
    SMART_PLUG = 'socket',
    SMART_LIGHT = 'switch',
    TRV = 'HEATING',
    BOILER = 'HOT_WATER',
    AIR_CONDITIONING = 'AIR_CONDITIONING',
    ENERGY_MONITOR = 'energy_monitor',
    HEATING_COOLING = 'HEATING|AIR_CONDITIONING',
}

export const smartHomeFilter = {
    ...SmartHomeDeviceModelTypeEnum,
    ...HvacDeviceModelTypeEnum,
};

export const enum SmartLightCapability {
    SWITCH = 'switch',
    INTENSITY = 'dimLevel',
    RESET = 'reset',
    HUE = 'hue',
}

export enum SmartACCapability {
    POWER = 'power',
    COOL = 'cool',
    HEAT = 'heat',
    MODE = 'mode',
    FAN_SPEEDS = 'fanSpeeds',
    SWING = 'swings',
}

export const enum SmartTrvCapability {
    POWER = 'power',
    TEMP_SET = 'temperatures',
}

export const enum SmartPlugCapability {
    SWITCH = 'switch',
    RESET = 'reset',
}

export const enum HotWaterCapability {
    POWER = 'power',
    TEMP_SET = 'temperatures',
}

export const enum SmartHomeHistoricalResolution {
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}

export const enum SmartDeviceErrorCode {
    COMMAND_FAILED = 'COMMAND_FAILED',
}
