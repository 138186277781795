import { useMemo } from 'react';

import { Moment, prepareInitialDate } from '@tools/utils';
import { ChargingModeData, ChargingModeModel } from '@store/types';
import {
    useHasWallbox,
    useSiteTimezone,
    useHasElectricCar,
    useRegistrationDate,
} from '@store/selectors';

import { GenericSmartChargingModelModeEnum } from '@swagger-http';

export const useHasEmobility = (): boolean => {
    const hasElectricCar = useHasElectricCar();
    const hasWallbox = useHasWallbox();

    return hasElectricCar || hasWallbox;
};

export const useHasEmobilityTokenExpired = (tokenExpiryDate?: string) => {
    return useMemo(() => {
        if (!tokenExpiryDate) {
            return false;
        }

        return Moment(tokenExpiryDate).isSameOrBefore(Moment());
    }, [tokenExpiryDate]);
};

export const useEmobilityReadingStartDate = (readingStartDate?: string) => {
    const timezone = useSiteTimezone();

    const defaultReadingStartDate = useRegistrationDate();

    return prepareInitialDate(
        readingStartDate || defaultReadingStartDate,
        timezone,
    );
};

export const useIsGridFriendlyCharging = (
    chargingModes: ChargingModeData<ChargingModeModel>[],
) => {
    return useMemo(
        () =>
            chargingModes.some(
                // prettier-ignore
                (item) => item.mode === GenericSmartChargingModelModeEnum.GridFriendlyCharging && item.parameters.active,
            ),
        [chargingModes],
    );
};
