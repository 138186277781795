import { PowerUnit } from '@tools/enums';
import { getKiloUnit, toLocalizedNumber } from '@tools/utils';
import { PowerData, FormattingCase, FormattingStrategy } from '@tools/types';

/**
 * TODO: Make sure to keep this function in sync with
 * the copy found in /cypress/commands/energy-flow.ts
 */
export const convertPowerValue = (
    power: number | undefined,
    unit: PowerUnit,
    initialValue?: string,
    printUnit = true,
): string => {
    let outputPower: string = initialValue || '?';
    let outputUnit: string = getKiloUnit(unit);

    if (power !== undefined) {
        if (power > 0 && power < 1) {
            outputUnit = unit;
            outputPower = toLocalizedNumber(power);
        } else {
            const division = power / 1000;

            if (Math.abs(division) < 0.1) {
                outputUnit = unit;
                outputPower = Math.round(power).toString();
            } else {
                outputPower = toLocalizedNumber(division);
            }
        }
    }

    return printUnit ? outputPower + ' ' + outputUnit : outputPower;
};

const convertAsNumber = (
    { value, unit }: PowerData,
    toUnit: PowerUnit,
): number => {
    let result = value;

    if (unit === PowerUnit.Wh || unit === PowerUnit.W) {
        result = value / 1000;
    }

    if (unit === PowerUnit.kWh && toUnit === PowerUnit.Wh) {
        result = value * 1000;
    }

    return result;
};

const applyStrategy = (value: number, strategy: FormattingStrategy): number => {
    let result = value;

    strategy.forEach((str: FormattingCase) => {
        if (str.staticFloatValue) {
            result = Number(result.toFixed() + '.' + str.staticFloatValue);
        }

        if (!str.staticFloatValue && str.precision !== undefined) {
            result = Number(result.toFixed(str.precision));
        }

        if (str.upscale) {
            result = value / 1000;
        }
    });

    return strategy.length ? result : value;
};

export const convertPowerUnit = (
    data: PowerData,
    toUnit: PowerUnit,
    defaultPrecision = 1,
    strategy?: FormattingStrategy,
): string | number => {
    const result = convertAsNumber(data, toUnit) || 0;

    return strategy
        ? applyStrategy(result, strategy)
        : Number(result.toFixed(defaultPrecision));
};
