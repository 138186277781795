/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Forbidden,
  GridConnectionPointHistoryAggregationResponseModel,
  MeterDeviceModel,
  Unauthorized,
} from '../models';

export interface GcpGetHistoryAggregatedRequest {
    fromDate: string;
    toDate: string;
    interval?: string;
    aggregation?: string;
    handleGaps?: string;
}

/**
 * 
 */
export class GCPApi extends runtime.BaseAPI {

    /**
     * Get meters
     */
    async gcpGetGcpRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MeterDeviceModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/gcp:read"]);
        }

        const response = await this.request({
            path: `/api/v1/gcp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get meters
     */
    async gcpGetGcp(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MeterDeviceModel>> {
        const response = await this.gcpGetGcpRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get historical graph data
     */
    async gcpGetHistoryAggregatedRaw(requestParameters: GcpGetHistoryAggregatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GridConnectionPointHistoryAggregationResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling gcpGetHistoryAggregated.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling gcpGetHistoryAggregated.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.aggregation !== undefined) {
            queryParameters['aggregation'] = requestParameters.aggregation;
        }

        if (requestParameters.handleGaps !== undefined) {
            queryParameters['handleGaps'] = requestParameters.handleGaps;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/gcp:read"]);
        }

        const response = await this.request({
            path: `/api/v1/gcp/history/aggregated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get historical graph data
     */
    async gcpGetHistoryAggregated(requestParameters: GcpGetHistoryAggregatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GridConnectionPointHistoryAggregationResponseModel>> {
        const response = await this.gcpGetHistoryAggregatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
