import { HistoricalData, HistoricalDataAction } from '@store/types';
import {
    HistoricalDataTypes,
    HistoricalResolution,
    HistoricalDataActionTypes,
} from '@store/enums';

const initialStateItem = {
    [HistoricalResolution.DAY15MIN]: [],
    [HistoricalResolution.DAY1HOUR]: [],
    [HistoricalResolution.WEEK]: [],
    [HistoricalResolution.MONTH]: [],
    [HistoricalResolution.YEAR]: [],
};

export const initialState: HistoricalData = {
    aggregatedData: { ...initialStateItem },
    donuts: {
        data: {
            day: null,
            week: null,
            month: null,
        },
        type: HistoricalDataTypes.DONUTS,
        startDate: '',
        timestamp: -1,
        shouldRender: true,
    },
    energyFlow: {
        data: {
            today: {
                gcpState: null,
                energyState: null,
                emobilityState: null,
                heatPumpState: null,
                requestEndDate: '',
                requestStartDate: '',
                shouldDisable30Days: true,
            },
            yesterday: {
                gcpState: null,
                energyState: null,
                emobilityState: null,
                heatPumpState: null,
                requestEndDate: '',
                requestStartDate: '',
                shouldDisable30Days: true,
            },
            '30d': {
                gcpState: null,
                energyState: null,
                emobilityState: null,
                heatPumpState: null,
                requestEndDate: '',
                requestStartDate: '',
                shouldDisable30Days: true,
            },
        },
        type: HistoricalDataTypes.EF,
        startDate: '',
        timestamp: -1,
    },
    pvEfficiency: {
        value: null,
        timestamp: new Date().toISOString(),
    },
    smartHome: {},
};

export default function reducer(
    state: HistoricalData = initialState,
    action: HistoricalDataAction,
): HistoricalData {
    const payload = action.payload;

    switch (action.type) {
        case HistoricalDataActionTypes.SET_AGGREGATED_DATA_FOR_DONUTS:
            return { ...state, donuts: payload };
        case HistoricalDataActionTypes.SET_AGGREGATED_DATA_FOR_ENERGY_FLOW:
            return { ...state, energyFlow: payload };
        case HistoricalDataActionTypes.SET_AGGREGATED_DATA:
            const currentState: any = { ...state };
            const { type, data, startDate, timestamp, resolution } = payload;
            const currentData = [...currentState[type][resolution]];

            if (
                currentData.find(
                    (item) =>
                        item.data.type === data.type &&
                        item.startDate === startDate,
                )
            ) {
                currentData.splice(
                    currentData.findIndex(
                        (item) =>
                            item.data.type === data.type &&
                            item.startDate === startDate,
                    ),
                    1,
                );
            }

            currentData.push({
                data,
                startDate,
                timestamp,
            });

            return {
                ...state,
                [type]: {
                    ...currentState[type],
                    [resolution]: currentData,
                },
            };
        case HistoricalDataActionTypes.SET_SMART_HOME_DEVICE_DATA:
            const { deviceId } = payload;

            return {
                ...state,
                smartHome: {
                    ...state.smartHome,
                    [deviceId]: {
                        data: payload.data,
                        timestamp: payload.timestamp,
                    },
                },
            };
        case HistoricalDataActionTypes.SET_PV_EFFICIENCY:
            return {
                ...state,
                pvEfficiency: payload,
            };
        case HistoricalDataActionTypes.CLEAR_CACHE:
            return initialState;
        default:
            return state;
    }
}
