import { HvacDeviceModel, HvacDeviceModelTypeEnum } from '@swagger-http';

import { useAppSelector, useHasWallbox } from '@store/selectors';

export const useSmartHomeDevices = () =>
    useAppSelector((state) => state.smartHome.devices);

export const useSmartHomeBranches = () =>
    useAppSelector((state) => state.smartHome.branches);

// TODO EONFEH-13649: update this for electric car
export const useHasSmartDevices = (
    considerWallbox: boolean = true,
): boolean => {
    const branches = useSmartHomeBranches();
    const wallboxInstalled = useHasWallbox();

    const { smartPlugs, smartLights, heatingCooling } = branches;

    return Boolean(
        considerWallbox
            ? smartLights || smartPlugs || heatingCooling || wallboxInstalled
            : smartLights || smartPlugs || heatingCooling,
    );
};

export const useHeatingDevices = () => {
    const devices = useSmartHomeDevices();
    return devices.filter(
        (device: HvacDeviceModel) =>
            device.type === HvacDeviceModelTypeEnum.Heating ||
            device.type === HvacDeviceModelTypeEnum.AirConditioning,
    );
};
