import {
    HvacDeviceModelTypeEnum,
    SmartHomeDeviceModelTypeEnum,
} from '@swagger-http';

import { SmartDeviceModel, SmartHomeState } from '@store/types';
import {
    SmartHomeAction,
    SmartHomeDevice,
    smartHomeFilter,
} from '@store/enums';

export const initialState: SmartHomeState = {
    devices: [],
    branches: {
        smartLights: false,
        smartPlugs: false,
        heatingCooling: false,
        hotWater: false,
    },
    filter: [
        smartHomeFilter.Switch,
        smartHomeFilter.Socket,
        smartHomeFilter.Heating,
        smartHomeFilter.HotWater,
    ],
    isRefreshing: false,
    vendorsState: [],
    loading: true,
    error: false,
};

export const decorate = (devices: SmartDeviceModel[]): SmartDeviceModel[] =>
    devices.map((device: SmartDeviceModel) => ({
        ...device,
        loading: false,
        error: {
            errorState: false,
            text: [],
            title: '',
        },
    }));

export const hasBranch = (
    devices: SmartDeviceModel[],
    types: SmartHomeDevice[],
) =>
    !!devices.find((device: SmartDeviceModel) =>
        // @ts-ignore
        types.find((type: SmartHomeDevice) => type === device.type),
    );

export const setInitialFilters = (
    branches: SmartHomeState['branches'],
): string[] => {
    const filters: string[] = [];

    if (branches.smartLights) {
        filters.push(SmartHomeDeviceModelTypeEnum.Switch);
    }

    if (branches.smartPlugs) {
        filters.push(SmartHomeDeviceModelTypeEnum.Socket);
    }

    if (branches.heatingCooling) {
        filters.push(HvacDeviceModelTypeEnum.Heating);
    }

    if (branches.hotWater) {
        filters.push(HvacDeviceModelTypeEnum.HotWater);
    }

    return filters;
};

export default function reducer(
    state: SmartHomeState = initialState,
    action: { type: SmartHomeAction; payload?: any },
): SmartHomeState {
    switch (action.type) {
        case SmartHomeAction.GET_ALL_DEVICES:
            return {
                ...state,
                devices: decorate(action.payload),
            };

        case SmartHomeAction.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case SmartHomeAction.SET_BRANCHES:
            return {
                ...state,
                branches: {
                    smartLights: hasBranch(action.payload, [
                        SmartHomeDevice.SMART_LIGHT,
                    ]),
                    smartPlugs: hasBranch(action.payload, [
                        SmartHomeDevice.SMART_PLUG,
                    ]),
                    heatingCooling: hasBranch(action.payload, [
                        SmartHomeDevice.TRV,
                        SmartHomeDevice.AIR_CONDITIONING,
                    ]),
                    hotWater: hasBranch(action.payload, [
                        SmartHomeDevice.BOILER,
                    ]),
                },
            };

        case SmartHomeAction.RESET_FILTER:
            return {
                ...state,
                filter: setInitialFilters(state.branches),
            };

        case SmartHomeAction.SET_FILTER:
            return {
                ...state,
                filter: action.payload,
            };

        case SmartHomeAction.REFRESH_DEVICES:
            return {
                ...state,
                isRefreshing: action.payload,
            };

        case SmartHomeAction.SET_VENDORS_STATE:
            return {
                ...state,
                vendorsState: action.payload,
            };

        case SmartHomeAction.SET_DEVICE_LOADING:
            const { deviceId: id, loading } = action.payload;

            return {
                ...state,
                devices: state.devices.map((device: SmartDeviceModel) => ({
                    ...device,
                    loading: device.deviceId === id ? loading : device.loading,
                })),
            };

        case SmartHomeAction.SET_DEVICE_ERROR:
            const { deviceId, error } = action.payload;

            return {
                ...state,
                devices: state.devices.map((device: SmartDeviceModel) => ({
                    ...device,
                    error: device.deviceId === deviceId ? error : device.error,
                })),
            };

        default: {
            return state;
        }
    }
}
