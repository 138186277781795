export enum DateTimeFormat {
    ONLY_TIME = 'onlyTime',
    ONLY_TIME_24 = 'onlyTime24',
    SHORT_DATE = 'shortDate',
    LONG_DATE = 'longDate',
    LONG_DATE_YEAR_TIME = 'longDateYearTime',
    SHORT_MONTH_AND_YEAR = 'shortMonthAndYear',
    GRAPH_WEEK = 'graphWeek',
    GRAPH_MONTH = 'graphMonth',
    GRAPH_YEAR_TOOLTIP = 'graphYearTooltip',
    DATE_AND_TIME = 'dateAndTime',
}
