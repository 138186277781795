import { EvseChargingSessionStateChangeData } from '@swagger-sse/api';

import { WallboxTelemetry } from '@store/types';

export const isTheWallboxCharging = (
    data: Pick<
        WallboxTelemetry,
        'isWallboxCharging' | 'status' | 'isWallboxOnline'
    >,
) => {
    const {
        Faulted,
        Charging,
        Finishing,
        Available,
        Preparing,
        StopFailed,
        StartFailed,
        SuspendedEV,
        StationInUse,
        SuspendedEVSE,
    } = EvseChargingSessionStateChangeData.StatusEnum;

    const { isWallboxCharging, status, isWallboxOnline } = data;

    if (isWallboxOnline === false) {
        return false;
    }

    switch (status) {
        case Faulted:
        case Finishing:
        case Preparing:
        case StartFailed:
        case SuspendedEVSE:
            return false;

        case StopFailed:
            return true;

        case Available:
        case Charging:
        case StationInUse:
        case SuspendedEV:
        default:
            return !!isWallboxCharging;
    }
};
