import { handleError } from './debug';
import { Scope, ScopeType } from '@tools/enums';
import { DEBUG, ROLES, SCOPES } from '@tools/constants';
import { scopes as hardCodedScopes } from '@config/env';

export const setScopesInLocalStorage = (scopes: string) => {
    if (!scopes) {
        return;
    }

    localStorage.setItem(SCOPES, JSON.stringify(scopes.split(',')));
};

export const getScopes = (): ScopeType => {
    const scopes = hardCodedScopes || localStorage.getItem(SCOPES);

    if (!scopes) {
        return [];
    }

    try {
        return JSON.parse(scopes);
    } catch (e) {
        return [];
    }
};

export const checkForScopes = (
    needles: ScopeType,
    fullMatch: boolean = false,
): boolean => {
    const haystack: ScopeType = getScopes();
    const result = fullMatch
        ? needles.every((scope: Scope) => haystack.includes(scope))
        : !!needles.filter((needle: Scope) => haystack.includes(needle)).length;

    const shouldDebug = localStorage.getItem(DEBUG);

    if (!result && shouldDebug === 'true') {
        // eslint-disable-next-line no-console
        console.trace('Missing permissions: ', needles);
    }

    return result;
};

export const canDownloadCSVOnCustomerBehalf = (): boolean => {
    const rawRoles = localStorage.getItem(ROLES);

    if (!rawRoles) {
        return false;
    }

    let roles: string[] = [];

    try {
        roles = JSON.parse(rawRoles);
    } catch (e) {
        handleError(e, "Failed parsing impersonated user's roles.");

        roles = [];
    }

    if (!Array.isArray(roles) || !roles.length) {
        return false;
    }

    return ['root', 'admin', 'owner'].some((role) => roles.includes(role));
};
