/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  Forbidden,
  SolarCloudBalanceV2ResponseModel,
  Unauthorized,
} from '../models';

/**
 * 
 */
export class SolarCloudApi extends runtime.BaseAPI {

    /**
     * Get solarcloud overall balance
     */
    async solarCloudGetSolarCloudBalanceV2Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SolarCloudBalanceV2ResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/pvb:read"]);
        }

        const response = await this.request({
            path: `/api/v2/solarcloud`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get solarcloud overall balance
     */
    async solarCloudGetSolarCloudBalanceV2(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SolarCloudBalanceV2ResponseModel> {
        const response = await this.solarCloudGetSolarCloudBalanceV2Raw(initOverrides);
        return await response.value();
    }

}
