import { DataAnalysisConfig } from '@tools/constants';
import moment from 'moment';
import { DurationInputArg1, DurationInputArg2 } from 'moment-timezone';

export const consentActionIsOlderThan = (
    consentActionDate: number | string | Date,
    subtractCount: DurationInputArg1 = DataAnalysisConfig.NEXT_TRIGGER_DELAY,
    subtractUnit: DurationInputArg2 = 'months',
) => {
    const inputDate = moment(consentActionDate);
    const today = moment();
    const subtractedDate = today.subtract(subtractCount, subtractUnit);

    return inputDate.isBefore(subtractedDate);
};
