import { Dispatch } from 'react';

import { UnknownFunction } from '@eon-home/react-library';

import {
    WallBoxApi,
    ResponseError,
    WallboxFirmwareModel,
    WallboxFirmwareModelStatusEnum,
} from '@swagger-http';

import { Scope } from '@tools/enums';
import { NotificationsActionTypes } from '@store/enums';
import {
    handleError,
    checkForScopes,
    createRequestConfiguration,
} from '@tools/utils';

export const getEmobilityFirmwareNotification =
    (configId: string) =>
    (dispatch: Dispatch<any>): Promise<void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_EV_READ])) {
            return Promise.resolve();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxGetWallboxFirmware({ configId })
            .then((data: WallboxFirmwareModel) => {
                dispatch({
                    type: NotificationsActionTypes.SET_EMOB_FIRMWARE_NOTIFICATION,
                    payload: data,
                });
            })
            .catch((e: ResponseError) => {
                handleError(e, 'Error calling wallBoxGetWallboxFirmware');
            });
    };

export const triggerEmobilityFirmwareUpdate =
    (configId: string, onSuccess: UnknownFunction, onError: UnknownFunction) =>
    (dispatch: Dispatch<any>): Promise<void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_EV_WRITE])) {
            return Promise.resolve();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxTriggerWallboxFirmwareUpdate({ configId })
            .then(() => {
                dispatch({
                    type: NotificationsActionTypes.SET_EMOB_FIRMWARE_NOTIFICATION,
                    payload: {
                        status: WallboxFirmwareModelStatusEnum.Requested,
                    },
                });

                onSuccess();
            })
            .catch((e: ResponseError) => {
                onError();

                handleError(
                    e,
                    'Error calling wallBoxTriggerWallboxFirmwareUpdate',
                );
            });
    };

export const getWallboxTamperNotifications =
    (configId: string) => (dispatch: Dispatch<any>) => {
        if (!checkForScopes([Scope.ENERGYDEVICES_EV_READ])) {
            return Promise.resolve();
        }

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxGetTamperingNotifications({ configId })
            .then((data) => {
                dispatch({
                    type: NotificationsActionTypes.SET_TAMPER_NOTIFICATIONS,
                    payload: {
                        emobility: {
                            tamper: data,
                        },
                    },
                });
            })
            .catch((e: ResponseError) => {
                if (e.response.status !== 404) {
                    handleError(
                        e,
                        'Error calling wallBoxGetTamperingNotifications',
                    );
                }
            });
    };
export const dismissWallboxTamperNotification =
    (configId: string, notificationId: string) =>
    (dispatch: Dispatch<any>): Promise<void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_EV_READ])) {
            return Promise.resolve();
        }

        dispatch({
            type: NotificationsActionTypes.REMOVE_TAMPER_NOTIFICATION,
            payload: {
                id: notificationId,
            },
        });

        return new WallBoxApi(createRequestConfiguration())
            .wallBoxAcknowledgeTamperingNotification({
                configId,
                notificationId,
            })
            .then(() => Promise.resolve())
            .catch((e: ResponseError) => {
                handleError(
                    e,
                    'Error calling wallBoxAcknowledgeTamperingNotification',
                );
            });
    };
