/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  DynamicPriceChargingModel,
  EvChargingHistoryAggregatedResponseModel,
  FastChargeModel,
  Forbidden,
  GenericSmartChargingModel,
  GridxSmartChargingModel,
  GridxSmartChargingResponseModel,
  NotFound,
  PlugAndChargeModel,
  PreconditionFailed,
  ProgramChargingModel,
  ProgramChargingResponseModel,
  SafetyChargingModel,
  SafetyChargingResponseModel,
  ScheduleChargingModel,
  ServiceUnavailable,
  SolarAssistedChargingModel,
  Unauthorized,
  WallBoxDeviceModifyModel,
  WallBoxDeviceResponseModel,
  WallBoxSetChargingModeResponseModel,
  WallBoxUpdateDeviceRequestModel,
  WallBoxUpdateElectricalVehicleRequestModel,
  WallBoxUpdateElectricalVehicleResponseModel,
  WallboxFirmwareModel,
  WallboxFirmwareUpdateSettingsModel,
  WallboxTamperingNotificationModel,
} from '../models';

export interface WallBoxAcknowledgeTamperingNotificationRequest {
    configId: string;
    notificationId: string;
}

export interface WallBoxAddStationRequest {
    configId: string;
    wallBoxUpdateDeviceRequestModel: WallBoxUpdateDeviceRequestModel;
}

export interface WallBoxDeleteSchedulingRequest {
    configId: string;
}

export interface WallBoxDeleteSmartChargingModeRequest {
    configId: string;
}

export interface WallBoxEditWallboxFirmwareSettingsRequest {
    configId: string;
    wallboxFirmwareUpdateSettingsModel: WallboxFirmwareUpdateSettingsModel;
}

export interface WallBoxGetElectricVehicleStationConfigurationRequest {
    configId: string;
}

export interface WallBoxGetSchedulingRequest {
    configId: string;
}

export interface WallBoxGetSmartChargingModeRequest {
    configId: string;
}

export interface WallBoxGetSmartChargingModesRequest {
    configId: string;
}

export interface WallBoxGetTamperingNotificationsRequest {
    configId: string;
}

export interface WallBoxGetWallboxFirmwareRequest {
    configId: string;
}

export interface WallBoxGetWallboxFirmwareSettingsRequest {
    configId: string;
}

export interface WallBoxHistoryRequest {
    fromDate: string;
    toDate: string;
    interval?: string;
    aggregation?: string;
}

export interface WallBoxSetChargingModeRequest {
    configId: string;
    wallBoxDeviceModifyModel: WallBoxDeviceModifyModel;
    skipDelay?: boolean;
}

export interface WallBoxSetGridxSmartChargingModeRequest {
    configId: string;
    gridxSmartChargingModel: GridxSmartChargingModel;
    takeInstantEffect?: string;
}

export interface WallBoxSetProgramChargingModeRequest {
    configId: string;
    programChargingModel: ProgramChargingModel;
    takeInstantEffect?: string;
}

export interface WallBoxSetSafetyChargingModeRequest {
    configId: string;
    safetyChargingModel: SafetyChargingModel;
    takeInstantEffect?: string;
}

export interface WallBoxSetSchedulingRequest {
    configId: string;
    genericSmartChargingModel: GenericSmartChargingModel;
    takeInstantEffect?: string;
}

export interface WallBoxSetSmartChargingModeRequest {
    configId: string;
    genericSmartChargingModel: GenericSmartChargingModel;
    takeInstantEffect?: string;
}

export interface WallBoxSetSmartChargingModeToDynamicPriceChargingRequest {
    configId: string;
    dynamicPriceChargingModel: DynamicPriceChargingModel;
    takeInstantEffect?: string;
}

export interface WallBoxSetSmartChargingModeToFastChargeRequest {
    configId: string;
    fastChargeModel: FastChargeModel;
}

export interface WallBoxSetSmartChargingModeToPlugAndChargeRequest {
    configId: string;
    plugAndChargeModel: PlugAndChargeModel;
}

export interface WallBoxSetSmartChargingModeToScheduleRequest {
    configId: string;
    scheduleChargingModel: ScheduleChargingModel;
    takeInstantEffect?: string;
}

export interface WallBoxSetSmartChargingModeToSolarAssistedChargingRequest {
    configId: string;
    solarAssistedChargingModel: SolarAssistedChargingModel;
    takeInstantEffect?: string;
}

export interface WallBoxTriggerWallboxFirmwareUpdateRequest {
    configId: string;
}

export interface WallBoxUpdateElectricVehicleStationConfigurationRequest {
    configId: string;
    wallBoxUpdateElectricalVehicleRequestModel: WallBoxUpdateElectricalVehicleRequestModel;
}

export interface WallBoxWallboxRequest {
    configId: string;
}

/**
 * 
 */
export class WallBoxApi extends runtime.BaseAPI {

    /**
     * Acknowledge tampering notification
     */
    async wallBoxAcknowledgeTamperingNotificationRaw(requestParameters: WallBoxAcknowledgeTamperingNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxAcknowledgeTamperingNotification.');
        }

        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling wallBoxAcknowledgeTamperingNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:write"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/{configId}/tampering/{notificationId}/acknowledge`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))).replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Acknowledge tampering notification
     */
    async wallBoxAcknowledgeTamperingNotification(requestParameters: WallBoxAcknowledgeTamperingNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.wallBoxAcknowledgeTamperingNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update wall-box device
     */
    async wallBoxAddStationRaw(requestParameters: WallBoxAddStationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WallBoxDeviceResponseModel>>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxAddStation.');
        }

        if (requestParameters.wallBoxUpdateDeviceRequestModel === null || requestParameters.wallBoxUpdateDeviceRequestModel === undefined) {
            throw new runtime.RequiredError('wallBoxUpdateDeviceRequestModel','Required parameter requestParameters.wallBoxUpdateDeviceRequestModel was null or undefined when calling wallBoxAddStation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:write"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/{configId}/vehicles`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.wallBoxUpdateDeviceRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update wall-box device
     */
    async wallBoxAddStation(requestParameters: WallBoxAddStationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WallBoxDeviceResponseModel>> {
        const response = await this.wallBoxAddStationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * DEPRECATED - Delete the scheduling for a specific wallbox
     */
    async wallBoxDeleteSchedulingRaw(requestParameters: WallBoxDeleteSchedulingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxDeleteScheduling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/scheduling`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * DEPRECATED - Delete the scheduling for a specific wallbox
     */
    async wallBoxDeleteScheduling(requestParameters: WallBoxDeleteSchedulingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.wallBoxDeleteSchedulingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the smart charging mode for a specific wallbox
     */
    async wallBoxDeleteSmartChargingModeRaw(requestParameters: WallBoxDeleteSmartChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxDeleteSmartChargingMode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/smartCharging`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete the smart charging mode for a specific wallbox
     */
    async wallBoxDeleteSmartChargingMode(requestParameters: WallBoxDeleteSmartChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.wallBoxDeleteSmartChargingModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit user settings for firmware update process
     */
    async wallBoxEditWallboxFirmwareSettingsRaw(requestParameters: WallBoxEditWallboxFirmwareSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WallboxFirmwareUpdateSettingsModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxEditWallboxFirmwareSettings.');
        }

        if (requestParameters.wallboxFirmwareUpdateSettingsModel === null || requestParameters.wallboxFirmwareUpdateSettingsModel === undefined) {
            throw new runtime.RequiredError('wallboxFirmwareUpdateSettingsModel','Required parameter requestParameters.wallboxFirmwareUpdateSettingsModel was null or undefined when calling wallBoxEditWallboxFirmwareSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:write"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/{configId}/firmware/update/settings`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.wallboxFirmwareUpdateSettingsModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Edit user settings for firmware update process
     */
    async wallBoxEditWallboxFirmwareSettings(requestParameters: WallBoxEditWallboxFirmwareSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WallboxFirmwareUpdateSettingsModel> {
        const response = await this.wallBoxEditWallboxFirmwareSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get wall-box electric vehicle configuration
     */
    async wallBoxGetElectricVehicleStationConfigurationRaw(requestParameters: WallBoxGetElectricVehicleStationConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WallBoxUpdateElectricalVehicleResponseModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxGetElectricVehicleStationConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:read"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/{configId}/ev`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get wall-box electric vehicle configuration
     */
    async wallBoxGetElectricVehicleStationConfiguration(requestParameters: WallBoxGetElectricVehicleStationConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WallBoxUpdateElectricalVehicleResponseModel> {
        const response = await this.wallBoxGetElectricVehicleStationConfigurationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * DEPRECATED - Get the scheduling for a specific wallbox
     */
    async wallBoxGetSchedulingRaw(requestParameters: WallBoxGetSchedulingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenericSmartChargingModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxGetScheduling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/scheduling`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * DEPRECATED - Get the scheduling for a specific wallbox
     */
    async wallBoxGetScheduling(requestParameters: WallBoxGetSchedulingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenericSmartChargingModel> {
        const response = await this.wallBoxGetSchedulingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the smart charging mode for a specific wallbox
     */
    async wallBoxGetSmartChargingModeRaw(requestParameters: WallBoxGetSmartChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenericSmartChargingModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxGetSmartChargingMode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/smartCharging`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the smart charging mode for a specific wallbox
     */
    async wallBoxGetSmartChargingMode(requestParameters: WallBoxGetSmartChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenericSmartChargingModel> {
        const response = await this.wallBoxGetSmartChargingModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all the smart charging modes for a specific wallbox
     */
    async wallBoxGetSmartChargingModesRaw(requestParameters: WallBoxGetSmartChargingModesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GenericSmartChargingModel>>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxGetSmartChargingModes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v3/wallboxes/{configId}/smartCharging`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get all the smart charging modes for a specific wallbox
     */
    async wallBoxGetSmartChargingModes(requestParameters: WallBoxGetSmartChargingModesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GenericSmartChargingModel>> {
        const response = await this.wallBoxGetSmartChargingModesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get unacknowledged tampering notifications
     */
    async wallBoxGetTamperingNotificationsRaw(requestParameters: WallBoxGetTamperingNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WallboxTamperingNotificationModel>>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxGetTamperingNotifications.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:read"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/{configId}/tampering`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get unacknowledged tampering notifications
     */
    async wallBoxGetTamperingNotifications(requestParameters: WallBoxGetTamperingNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WallboxTamperingNotificationModel>> {
        const response = await this.wallBoxGetTamperingNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get wallbox device firmware status
     */
    async wallBoxGetWallboxFirmwareRaw(requestParameters: WallBoxGetWallboxFirmwareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WallboxFirmwareModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxGetWallboxFirmware.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:read"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/{configId}/firmware`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get wallbox device firmware status
     */
    async wallBoxGetWallboxFirmware(requestParameters: WallBoxGetWallboxFirmwareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WallboxFirmwareModel> {
        const response = await this.wallBoxGetWallboxFirmwareRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get user settings for firmware update process
     */
    async wallBoxGetWallboxFirmwareSettingsRaw(requestParameters: WallBoxGetWallboxFirmwareSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WallboxFirmwareUpdateSettingsModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxGetWallboxFirmwareSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:read"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/{configId}/firmware/update/settings`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get user settings for firmware update process
     */
    async wallBoxGetWallboxFirmwareSettings(requestParameters: WallBoxGetWallboxFirmwareSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WallboxFirmwareUpdateSettingsModel> {
        const response = await this.wallBoxGetWallboxFirmwareSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get history graph data
     */
    async wallBoxHistoryRaw(requestParameters: WallBoxHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EvChargingHistoryAggregatedResponseModel>>> {
        if (requestParameters.fromDate === null || requestParameters.fromDate === undefined) {
            throw new runtime.RequiredError('fromDate','Required parameter requestParameters.fromDate was null or undefined when calling wallBoxHistory.');
        }

        if (requestParameters.toDate === null || requestParameters.toDate === undefined) {
            throw new runtime.RequiredError('toDate','Required parameter requestParameters.toDate was null or undefined when calling wallBoxHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.fromDate !== undefined) {
            queryParameters['fromDate'] = requestParameters.fromDate;
        }

        if (requestParameters.toDate !== undefined) {
            queryParameters['toDate'] = requestParameters.toDate;
        }

        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }

        if (requestParameters.aggregation !== undefined) {
            queryParameters['aggregation'] = requestParameters.aggregation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:read"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/history/aggregated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get history graph data
     */
    async wallBoxHistory(requestParameters: WallBoxHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EvChargingHistoryAggregatedResponseModel>> {
        const response = await this.wallBoxHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send command to a wall box device
     */
    async wallBoxSetChargingModeRaw(requestParameters: WallBoxSetChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WallBoxSetChargingModeResponseModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxSetChargingMode.');
        }

        if (requestParameters.wallBoxDeviceModifyModel === null || requestParameters.wallBoxDeviceModifyModel === undefined) {
            throw new runtime.RequiredError('wallBoxDeviceModifyModel','Required parameter requestParameters.wallBoxDeviceModifyModel was null or undefined when calling wallBoxSetChargingMode.');
        }

        const queryParameters: any = {};

        if (requestParameters.skipDelay !== undefined) {
            queryParameters['skipDelay'] = requestParameters.skipDelay;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:write"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/{configId}/command`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.wallBoxDeviceModifyModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Send command to a wall box device
     */
    async wallBoxSetChargingMode(requestParameters: WallBoxSetChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WallBoxSetChargingModeResponseModel> {
        const response = await this.wallBoxSetChargingModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the smart charging mode to \"gridxSmartCharging\" for a specific wallbox
     */
    async wallBoxSetGridxSmartChargingModeRaw(requestParameters: WallBoxSetGridxSmartChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GridxSmartChargingResponseModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxSetGridxSmartChargingMode.');
        }

        if (requestParameters.gridxSmartChargingModel === null || requestParameters.gridxSmartChargingModel === undefined) {
            throw new runtime.RequiredError('gridxSmartChargingModel','Required parameter requestParameters.gridxSmartChargingModel was null or undefined when calling wallBoxSetGridxSmartChargingMode.');
        }

        const queryParameters: any = {};

        if (requestParameters.takeInstantEffect !== undefined) {
            queryParameters['takeInstantEffect'] = requestParameters.takeInstantEffect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/smartCharging/gridxSmartCharge`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.gridxSmartChargingModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set the smart charging mode to \"gridxSmartCharging\" for a specific wallbox
     */
    async wallBoxSetGridxSmartChargingMode(requestParameters: WallBoxSetGridxSmartChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GridxSmartChargingResponseModel> {
        const response = await this.wallBoxSetGridxSmartChargingModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the smart charging mode to \"programCharging\" for a specific wallbox
     */
    async wallBoxSetProgramChargingModeRaw(requestParameters: WallBoxSetProgramChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProgramChargingResponseModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxSetProgramChargingMode.');
        }

        if (requestParameters.programChargingModel === null || requestParameters.programChargingModel === undefined) {
            throw new runtime.RequiredError('programChargingModel','Required parameter requestParameters.programChargingModel was null or undefined when calling wallBoxSetProgramChargingMode.');
        }

        const queryParameters: any = {};

        if (requestParameters.takeInstantEffect !== undefined) {
            queryParameters['takeInstantEffect'] = requestParameters.takeInstantEffect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/smartCharging/programCharge`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.programChargingModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set the smart charging mode to \"programCharging\" for a specific wallbox
     */
    async wallBoxSetProgramChargingMode(requestParameters: WallBoxSetProgramChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProgramChargingResponseModel> {
        const response = await this.wallBoxSetProgramChargingModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the smart charging mode to \"safetyCharging\" for a specific wallbox
     */
    async wallBoxSetSafetyChargingModeRaw(requestParameters: WallBoxSetSafetyChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SafetyChargingResponseModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxSetSafetyChargingMode.');
        }

        if (requestParameters.safetyChargingModel === null || requestParameters.safetyChargingModel === undefined) {
            throw new runtime.RequiredError('safetyChargingModel','Required parameter requestParameters.safetyChargingModel was null or undefined when calling wallBoxSetSafetyChargingMode.');
        }

        const queryParameters: any = {};

        if (requestParameters.takeInstantEffect !== undefined) {
            queryParameters['takeInstantEffect'] = requestParameters.takeInstantEffect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/smartCharging/safetyCharging`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.safetyChargingModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set the smart charging mode to \"safetyCharging\" for a specific wallbox
     */
    async wallBoxSetSafetyChargingMode(requestParameters: WallBoxSetSafetyChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SafetyChargingResponseModel> {
        const response = await this.wallBoxSetSafetyChargingModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * DEPRECATED - Set the scheduling for a specific wallbox
     */
    async wallBoxSetSchedulingRaw(requestParameters: WallBoxSetSchedulingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenericSmartChargingModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxSetScheduling.');
        }

        if (requestParameters.genericSmartChargingModel === null || requestParameters.genericSmartChargingModel === undefined) {
            throw new runtime.RequiredError('genericSmartChargingModel','Required parameter requestParameters.genericSmartChargingModel was null or undefined when calling wallBoxSetScheduling.');
        }

        const queryParameters: any = {};

        if (requestParameters.takeInstantEffect !== undefined) {
            queryParameters['takeInstantEffect'] = requestParameters.takeInstantEffect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/scheduling`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.genericSmartChargingModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * DEPRECATED - Set the scheduling for a specific wallbox
     */
    async wallBoxSetScheduling(requestParameters: WallBoxSetSchedulingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenericSmartChargingModel> {
        const response = await this.wallBoxSetSchedulingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * DEPRECATED - Set the smart charging mode for a specific wallbox
     */
    async wallBoxSetSmartChargingModeRaw(requestParameters: WallBoxSetSmartChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenericSmartChargingModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxSetSmartChargingMode.');
        }

        if (requestParameters.genericSmartChargingModel === null || requestParameters.genericSmartChargingModel === undefined) {
            throw new runtime.RequiredError('genericSmartChargingModel','Required parameter requestParameters.genericSmartChargingModel was null or undefined when calling wallBoxSetSmartChargingMode.');
        }

        const queryParameters: any = {};

        if (requestParameters.takeInstantEffect !== undefined) {
            queryParameters['takeInstantEffect'] = requestParameters.takeInstantEffect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/smartCharging`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.genericSmartChargingModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * DEPRECATED - Set the smart charging mode for a specific wallbox
     */
    async wallBoxSetSmartChargingMode(requestParameters: WallBoxSetSmartChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenericSmartChargingModel> {
        const response = await this.wallBoxSetSmartChargingModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the smart charging mode to \"dynamicPriceCharging\" for a specific wallbox
     */
    async wallBoxSetSmartChargingModeToDynamicPriceChargingRaw(requestParameters: WallBoxSetSmartChargingModeToDynamicPriceChargingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DynamicPriceChargingModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxSetSmartChargingModeToDynamicPriceCharging.');
        }

        if (requestParameters.dynamicPriceChargingModel === null || requestParameters.dynamicPriceChargingModel === undefined) {
            throw new runtime.RequiredError('dynamicPriceChargingModel','Required parameter requestParameters.dynamicPriceChargingModel was null or undefined when calling wallBoxSetSmartChargingModeToDynamicPriceCharging.');
        }

        const queryParameters: any = {};

        if (requestParameters.takeInstantEffect !== undefined) {
            queryParameters['takeInstantEffect'] = requestParameters.takeInstantEffect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/smartCharging/dynamicPriceCharging`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.dynamicPriceChargingModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set the smart charging mode to \"dynamicPriceCharging\" for a specific wallbox
     */
    async wallBoxSetSmartChargingModeToDynamicPriceCharging(requestParameters: WallBoxSetSmartChargingModeToDynamicPriceChargingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DynamicPriceChargingModel> {
        const response = await this.wallBoxSetSmartChargingModeToDynamicPriceChargingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the fast charge mode to \"forcedEv\" for a specific wallbox
     */
    async wallBoxSetSmartChargingModeToFastChargeRaw(requestParameters: WallBoxSetSmartChargingModeToFastChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FastChargeModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxSetSmartChargingModeToFastCharge.');
        }

        if (requestParameters.fastChargeModel === null || requestParameters.fastChargeModel === undefined) {
            throw new runtime.RequiredError('fastChargeModel','Required parameter requestParameters.fastChargeModel was null or undefined when calling wallBoxSetSmartChargingModeToFastCharge.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/smartCharging/fastCharge`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.fastChargeModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set the fast charge mode to \"forcedEv\" for a specific wallbox
     */
    async wallBoxSetSmartChargingModeToFastCharge(requestParameters: WallBoxSetSmartChargingModeToFastChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FastChargeModel> {
        const response = await this.wallBoxSetSmartChargingModeToFastChargeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the plug and charge mode to \"homePlugAndCharge\" for a specific wallbox
     */
    async wallBoxSetSmartChargingModeToPlugAndChargeRaw(requestParameters: WallBoxSetSmartChargingModeToPlugAndChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlugAndChargeModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxSetSmartChargingModeToPlugAndCharge.');
        }

        if (requestParameters.plugAndChargeModel === null || requestParameters.plugAndChargeModel === undefined) {
            throw new runtime.RequiredError('plugAndChargeModel','Required parameter requestParameters.plugAndChargeModel was null or undefined when calling wallBoxSetSmartChargingModeToPlugAndCharge.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/smartCharging/plugAndCharge`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.plugAndChargeModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set the plug and charge mode to \"homePlugAndCharge\" for a specific wallbox
     */
    async wallBoxSetSmartChargingModeToPlugAndCharge(requestParameters: WallBoxSetSmartChargingModeToPlugAndChargeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlugAndChargeModel> {
        const response = await this.wallBoxSetSmartChargingModeToPlugAndChargeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the smart charging mode to \"schedule\" for a specific wallbox
     */
    async wallBoxSetSmartChargingModeToScheduleRaw(requestParameters: WallBoxSetSmartChargingModeToScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduleChargingModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxSetSmartChargingModeToSchedule.');
        }

        if (requestParameters.scheduleChargingModel === null || requestParameters.scheduleChargingModel === undefined) {
            throw new runtime.RequiredError('scheduleChargingModel','Required parameter requestParameters.scheduleChargingModel was null or undefined when calling wallBoxSetSmartChargingModeToSchedule.');
        }

        const queryParameters: any = {};

        if (requestParameters.takeInstantEffect !== undefined) {
            queryParameters['takeInstantEffect'] = requestParameters.takeInstantEffect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/smartCharging/schedule`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.scheduleChargingModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set the smart charging mode to \"schedule\" for a specific wallbox
     */
    async wallBoxSetSmartChargingModeToSchedule(requestParameters: WallBoxSetSmartChargingModeToScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduleChargingModel> {
        const response = await this.wallBoxSetSmartChargingModeToScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the smart charging mode to \"solar assisted charging\" for a specific wallbox
     */
    async wallBoxSetSmartChargingModeToSolarAssistedChargingRaw(requestParameters: WallBoxSetSmartChargingModeToSolarAssistedChargingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SolarAssistedChargingModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxSetSmartChargingModeToSolarAssistedCharging.');
        }

        if (requestParameters.solarAssistedChargingModel === null || requestParameters.solarAssistedChargingModel === undefined) {
            throw new runtime.RequiredError('solarAssistedChargingModel','Required parameter requestParameters.solarAssistedChargingModel was null or undefined when calling wallBoxSetSmartChargingModeToSolarAssistedCharging.');
        }

        const queryParameters: any = {};

        if (requestParameters.takeInstantEffect !== undefined) {
            queryParameters['takeInstantEffect'] = requestParameters.takeInstantEffect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v2/wallboxes/{configId}/smartCharging/solarAssistedCharging`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.solarAssistedChargingModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set the smart charging mode to \"solar assisted charging\" for a specific wallbox
     */
    async wallBoxSetSmartChargingModeToSolarAssistedCharging(requestParameters: WallBoxSetSmartChargingModeToSolarAssistedChargingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SolarAssistedChargingModel> {
        const response = await this.wallBoxSetSmartChargingModeToSolarAssistedChargingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of wall box devices
     */
    async wallBoxStationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WallBoxDeviceResponseModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:read"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get list of wall box devices
     */
    async wallBoxStations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WallBoxDeviceResponseModel>> {
        const response = await this.wallBoxStationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Trigger wallbox device firmware update process
     */
    async wallBoxTriggerWallboxFirmwareUpdateRaw(requestParameters: WallBoxTriggerWallboxFirmwareUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxTriggerWallboxFirmwareUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:write"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/{configId}/firmware/update`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Trigger wallbox device firmware update process
     */
    async wallBoxTriggerWallboxFirmwareUpdate(requestParameters: WallBoxTriggerWallboxFirmwareUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.wallBoxTriggerWallboxFirmwareUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update wall-box electric vehicle configuration
     */
    async wallBoxUpdateElectricVehicleStationConfigurationRaw(requestParameters: WallBoxUpdateElectricVehicleStationConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxUpdateElectricVehicleStationConfiguration.');
        }

        if (requestParameters.wallBoxUpdateElectricalVehicleRequestModel === null || requestParameters.wallBoxUpdateElectricalVehicleRequestModel === undefined) {
            throw new runtime.RequiredError('wallBoxUpdateElectricalVehicleRequestModel','Required parameter requestParameters.wallBoxUpdateElectricalVehicleRequestModel was null or undefined when calling wallBoxUpdateElectricVehicleStationConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:write"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/{configId}/ev`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.wallBoxUpdateElectricalVehicleRequestModel,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update wall-box electric vehicle configuration
     */
    async wallBoxUpdateElectricVehicleStationConfiguration(requestParameters: WallBoxUpdateElectricVehicleStationConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.wallBoxUpdateElectricVehicleStationConfigurationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a wall box device
     */
    async wallBoxWallboxRaw(requestParameters: WallBoxWallboxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WallBoxDeviceResponseModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling wallBoxWallbox.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/energydevices/ev:read"]);
        }

        const response = await this.request({
            path: `/api/v1/wallboxes/{configId}`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Find a wall box device
     */
    async wallBoxWallbox(requestParameters: WallBoxWallboxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WallBoxDeviceResponseModel> {
        const response = await this.wallBoxWallboxRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
