import { Scope } from '../enums';

export const smartDevicesReadScopes = [
    Scope.SMARTHOME_HEATINGCOOLING_READ,
    Scope.SMARTHOME_LIGHTS_READ,
    Scope.SMARTHOME_PLUGS_READ,
];

export const energyDevicesReadScopes = [
    Scope.ENERGYDEVICES_GCP_READ,
    Scope.ENERGYDEVICES_PVB_READ,
    Scope.ENERGYDEVICES_EV_READ,
];
