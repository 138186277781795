import { SpotPriceActionTypes } from './enums';
import { SpotPrice, SpotPriceAction } from './types';

export const initialState: SpotPrice = {
    data: null,
    error: null,
    loading: false,
};

export default function reducer(
    state: SpotPrice = initialState,
    action: SpotPriceAction,
): SpotPrice {
    switch (action.type) {
        case SpotPriceActionTypes.SET_USER_SPOT_PRICE_DATA:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
}
