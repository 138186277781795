import { AnyAction, combineReducers } from 'redux';

import { goToLogin } from '@tools/utils';
import { RootState } from '@store/types';
import { initialState } from '.';
import { GenericActionTypes, SettingsActionTypes } from '@store/enums';

import cms from '@store/branches/cms/reducer';
import user from '@store/branches/user/reducer';
import auth from '@store/branches/auth/reducer';
import energy from '@store/branches/energy/reducer';
import tariff from '@store/branches/tariff/reducer';
import weather from '@store/branches/weather/reducer';
import liveData from '@store/branches/live-data/reducer';
import notifications from '@store/branches/notifications/reducer';
import settings from '@store/branches/settings/reducer';
import insights from '@store/branches/insights/reducer';
import smartHome from '@store/branches/smart-home/reducer';
import emobility from '@store/branches/emobility/reducer';
import spotPrice from '@store/branches/spot-price/reducer';
import historicalData from '@store/branches/historical-data/reducer';
import aggregatedData from '@store/branches/aggregated-data/reducer';
import historicalEnergyFlow from '@store/branches/energy-flow/reducer';

const appReducer = () =>
    combineReducers({
        aggregatedData,
        auth,
        cms,
        emobility,
        energy,
        historicalData,
        historicalEnergyFlow,
        insights,
        liveData,
        notifications,
        settings,
        smartHome,
        spotPrice,
        tariff,
        user,
        weather,
    });

export const rootReducer = () => (state: RootState, action: AnyAction) => {
    if (action.type === SettingsActionTypes.REQUEST_LOGOUT) {
        goToLogin(action.payload ? action.payload : '');
    }

    if (action.type === GenericActionTypes.RESET_STATE) {
        state = {
            ...initialState,
            auth: {
                token: '',
                threshold: 0,
                refreshToken: '',
                isLoggingOut: true,
            },
        };
    }

    return appReducer()(state, action);
};
