import { Dispatch } from 'redux';

import {
    SolarCloudApi,
    ResponseError,
    SolarCloudBalanceV2ResponseModel,
} from '@swagger-http';

import { Scope } from '@tools/enums';
import { HistoricalDataAction } from '@store/types';
import { LiveDataActionTypes, HistoricalDataActionTypes } from '@store/enums';
import {
    handleError,
    checkForScopes,
    createRequestConfiguration,
} from '@tools/utils';

export const setHistoricalData = (
    type: HistoricalDataActionTypes,
    payload: any,
): HistoricalDataAction => ({
    type,
    payload,
});

export const getSolarBalanceV2 =
    () =>
    async (dispatch: Dispatch<any>): Promise<void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_GCP_READ])) {
            return Promise.resolve();
        }

        return new SolarCloudApi(createRequestConfiguration())
            .solarCloudGetSolarCloudBalanceV2()
            .then((response: SolarCloudBalanceV2ResponseModel) => {
                dispatch({
                    type: LiveDataActionTypes.GCP_TELEMETRIES,
                    payload: {
                        solarCloudBalanceV2: response.solarCloudBalanceTotal,
                        solarCloudBalanceTimestampV2: response.lastUpdate,
                    },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(e, 'Error fetching solar balance v2: ');
            });
    };
