import { LiveDataActionTypes } from '@store/enums';
import { LiveDataAction, LiveDataEvent } from '@store/types';

export class UnusableEvent implements LiveDataEvent {
    public getAction(): LiveDataAction {
        return {
            type: LiveDataActionTypes.UNUSABLE_EVENT,
            payload: null,
        };
    }
}
