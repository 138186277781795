import { TariffActionTypes } from './enums';
import { Tariff, TariffAction } from './types';

export const initialState: Tariff = {
    active: {
        data: null,
        error: false,
        loading: false,
        widgetVisible: false,
    },
    history: {
        data: null,
        error: false,
        loading: false,
    },
};

export default function reducer(
    state: Tariff = initialState,
    action: TariffAction,
): Tariff {
    switch (action.type) {
        case TariffActionTypes.SET_USER_ACTIVE_TARIFF_DATA:
            return {
                ...state,
                active: {
                    ...state.active,
                    ...action.payload,
                },
            };

        case TariffActionTypes.SET_USER_HISTORICAL_TARIFF_DATA:
            return {
                ...state,
                history: {
                    ...state.history,
                    ...action.payload,
                },
            };

        default:
            return state;
    }
}
