import { NotificationsState } from '@store/types';
import { NotificationsActionTypes } from '@store/enums';

export const initialState: NotificationsState = {
    emobility: {
        firmware: undefined,
        tamper: [],
    },
};

export default function reducer(
    state: NotificationsState = initialState,
    action: any,
): NotificationsState {
    switch (action.type) {
        case NotificationsActionTypes.SET_EMOB_FIRMWARE_NOTIFICATION: {
            return {
                ...state,
                emobility: {
                    ...state.emobility,
                    firmware: {
                        ...state.emobility.firmware,
                        ...action.payload,
                    },
                },
            };
        }

        case NotificationsActionTypes.SET_TAMPER_NOTIFICATIONS: {
            return {
                ...state,
                emobility: {
                    ...state.emobility,
                    tamper: action?.payload?.emobility?.tamper
                        ? [...action?.payload?.emobility?.tamper]
                        : [],
                },
            };
        }

        case NotificationsActionTypes.UPDATE_TAMPER_NOTIFICATION: {
            const id = action.payload.id;
            const match = state.emobility.tamper.find((item) => item.id === id);

            const newState = match
                ? state.emobility.tamper.map((item) => {
                      if (item.id === id) {
                          return { ...item, ...action.payload };
                      }

                      return item;
                  })
                : [...state.emobility.tamper, { ...action.payload }];

            return {
                ...state,
                emobility: {
                    ...state.emobility,
                    tamper: newState,
                },
            };
        }

        case NotificationsActionTypes.REMOVE_TAMPER_NOTIFICATION: {
            const id = action.payload.id;
            const newState = state.emobility.tamper.filter(
                (item) => item.id !== id,
            );

            return {
                ...state,
                emobility: {
                    ...state.emobility,
                    tamper: newState,
                },
            };
        }

        default: {
            return state;
        }
    }
}
