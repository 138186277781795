/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Forbidden,
  Unauthorized,
  UserPushNotificationAddTokenModel,
  UserPushNotificationRemoveTokenModel,
} from '../models';

export interface PushNotificationAddUpdatePushNotificationTokenRequest {
    userPushNotificationAddTokenModel?: UserPushNotificationAddTokenModel;
}

export interface PushNotificationRemovePushNotificationTokenRequest {
    userPushNotificationRemoveTokenModel?: UserPushNotificationRemoveTokenModel;
}

/**
 * 
 */
export class PushNotificationApi extends runtime.BaseAPI {

    /**
     * Add/update push notification token
     */
    async pushNotificationAddUpdatePushNotificationTokenRaw(requestParameters: PushNotificationAddUpdatePushNotificationTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/me/push_notification/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userPushNotificationAddTokenModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add/update push notification token
     */
    async pushNotificationAddUpdatePushNotificationToken(requestParameters: PushNotificationAddUpdatePushNotificationTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pushNotificationAddUpdatePushNotificationTokenRaw(requestParameters, initOverrides);
    }

    /**
     * Remove push notification token
     */
    async pushNotificationRemovePushNotificationTokenRaw(requestParameters: PushNotificationRemovePushNotificationTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:write"]);
        }

        const response = await this.request({
            path: `/api/v1/me/push_notification/token`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.userPushNotificationRemoveTokenModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove push notification token
     */
    async pushNotificationRemovePushNotificationToken(requestParameters: PushNotificationRemovePushNotificationTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pushNotificationRemovePushNotificationTokenRaw(requestParameters, initOverrides);
    }

}
