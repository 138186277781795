export const enum AggregationType {
    FIRST = 'first',
    LAST = 'last',
}

export enum HistoricalResolution {
    DAY15MIN = 'day15min',
    DAY1HOUR = 'day1hour',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}

export const enum HistoricalInterval {
    FIFTEEN_MINUTES = 'PT15M',
    ONE_HOUR = 'PT1H',
    ONE_DAY = 'P1D',
    ONE_MONTH = 'P1M',
}

export const enum HistoricalComparisonInterval {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
}

export enum ExtendedHistoricalResolution {
    ALL = 'all',
    THIRTY_DAYS = 'thirtyDays',
}

export const enum HistoricalDataTypes {
    BT = 'battery',
    DONUTS = 'donuts',
    EC = 'aggregatedData',
    EF = 'energyFlow',
    PV = 'solarPanel',
    EMOBILITY = 'emobility',
    IMPORT_EXPORT = 'importExport',
    SOLAR_CLOUD_BALANCE = 'solarCloudBalance',
}

export const enum HistoricalDataActionTypes {
    CLEAR_CACHE = 'CLEAR_CACHE',
    SET_PV_EFFICIENCY = 'SET_PV_EFFICIENCY',
    SET_AGGREGATED_DATA = 'SET_AGGREGATED_DATA',
    SET_SMART_HOME_DEVICE_DATA = 'SET_SMART_HOME_DEVICE_DATA',
    SET_AGGREGATED_DATA_FOR_DONUTS = 'SET_AGGREGATED_DATA_FOR_DONUTS',
    SET_AGGREGATED_DATA_FOR_ENERGY_FLOW = 'SET_AGGREGATED_DATA_FOR_ENERGY_FLOW',
}

export enum SmartHomeHistoricalDataTypes {
    SMART_PLUG = 'SMART_PLUG',
    HEATING_COOLING = 'HEATING_COOLING',
}

export type Res = HistoricalResolution | ExtendedHistoricalResolution;

export enum SolarCloudStatus {
    MIXED = 'mixed', // Mixed: just a part of the days has active solarCloud
    ACTIVE = 'active', // Active during the whole chart period
    INACTIVE = 'inactive', // Inactive during the whole chart period
}
