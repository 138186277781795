export * from './branches/aggregated-data/enums';
export * from './branches/auth/enums';
export * from './branches/cms/enums';
export * from './branches/emobility/enums';
export * from './branches/energy/enums';
export * from './branches/energy-flow/enums';
export * from './branches/historical-data/enums';
export * from './branches/insights/enums';
export * from './branches/live-data/enums';
export * from './branches/notifications/enums';
export * from './branches/settings/enums';
export * from './branches/smart-home/enums';
export * from './branches/spot-price/enums';
export * from './branches/tariff/enums';
export * from './branches/user/enums';
export * from './branches/weather/enums';

export const enum GenericActionTypes {
    RESET_STATE = 'RESET_STATE',
}

export const enum ComponentActionTypes {
    SET_DATA = 'SET_DATA',
}

export const enum ActionsRequired {
    RELINK_TADO = 'relink-tado',
    ACCEPT_TERMS = 'accept-terms-and-conditions',
}

export const enum RefreshTokenActionTypes {
    OVERWRITE_REFRESH_TOKEN = 'OVERWRITE_REFRESH_TOKEN',
}

export const enum BrandName {
    BRAND_EON = 'eon',
    BRAND_FOTON = 'foton',
    BRAND_NEXT_HOME = 'eon-next-home',
    BRAND_EON_CROATIA = 'eon-home-croatia',
}
