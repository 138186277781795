/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CampaignResponseModel,
  Conflict,
  SignUpPreflightResponseModel,
  SignUpRequestModel,
  SignUpResponseModel,
  StepUpRequestModel,
  TenantAffiliateResponseModel,
} from '../models';

export interface SignUpCreateSignUpRequest {
    state: string;
    signUpRequestModel: SignUpRequestModel;
}

export interface SignUpCreateStepUpRequest {
    state: string;
    stepUpRequestModel: StepUpRequestModel;
}

export interface SignUpGetCampaignRequest {
    campaignUUID: string;
}

export interface SignUpGetTenantAffiliateAssignmentRequest {
    originLocal?: string;
}

/**
 * 
 */
export class SignUpApi extends runtime.BaseAPI {

    /**
     * Create account
     */
    async signUpCreateSignUpRaw(requestParameters: SignUpCreateSignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignUpResponseModel>> {
        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling signUpCreateSignUp.');
        }

        if (requestParameters.signUpRequestModel === null || requestParameters.signUpRequestModel === undefined) {
            throw new runtime.RequiredError('signUpRequestModel','Required parameter requestParameters.signUpRequestModel was null or undefined when calling signUpCreateSignUp.');
        }

        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.signUpRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Create account
     */
    async signUpCreateSignUp(requestParameters: SignUpCreateSignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignUpResponseModel> {
        const response = await this.signUpCreateSignUpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Step Up Registration
     */
    async signUpCreateStepUpRaw(requestParameters: SignUpCreateStepUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignUpResponseModel>> {
        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling signUpCreateStepUp.');
        }

        if (requestParameters.stepUpRequestModel === null || requestParameters.stepUpRequestModel === undefined) {
            throw new runtime.RequiredError('stepUpRequestModel','Required parameter requestParameters.stepUpRequestModel was null or undefined when calling signUpCreateStepUp.');
        }

        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/signup`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.stepUpRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Step Up Registration
     */
    async signUpCreateStepUp(requestParameters: SignUpCreateStepUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignUpResponseModel> {
        const response = await this.signUpCreateStepUpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get campaign details
     * Get campaign details
     */
    async signUpGetCampaignRaw(requestParameters: SignUpGetCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CampaignResponseModel>> {
        if (requestParameters.campaignUUID === null || requestParameters.campaignUUID === undefined) {
            throw new runtime.RequiredError('campaignUUID','Required parameter requestParameters.campaignUUID was null or undefined when calling signUpGetCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/signup/campaign/{campaignUUID}`.replace(`{${"campaignUUID"}}`, encodeURIComponent(String(requestParameters.campaignUUID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get campaign details
     * Get campaign details
     */
    async signUpGetCampaign(requestParameters: SignUpGetCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CampaignResponseModel> {
        const response = await this.signUpGetCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get tenant affiliate info
     * Get tenant affiliate info
     */
    async signUpGetTenantAffiliateAssignmentRaw(requestParameters: SignUpGetTenantAffiliateAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TenantAffiliateResponseModel>>> {
        const queryParameters: any = {};

        if (requestParameters.originLocal !== undefined) {
            queryParameters['originLocal'] = requestParameters.originLocal;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/signup/tenant-affiliate-assignment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get tenant affiliate info
     * Get tenant affiliate info
     */
    async signUpGetTenantAffiliateAssignment(requestParameters: SignUpGetTenantAffiliateAssignmentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TenantAffiliateResponseModel>> {
        const response = await this.signUpGetTenantAffiliateAssignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * For creating a sign-up you need some information from the backend first such as CSRF token and endpoint url.
     * Get sign-up information
     */
    async signUpSignUpUriRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignUpPreflightResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/signup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * For creating a sign-up you need some information from the backend first such as CSRF token and endpoint url.
     * Get sign-up information
     */
    async signUpSignUpUri(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignUpPreflightResponseModel> {
        const response = await this.signUpSignUpUriRaw(initOverrides);
        return await response.value();
    }

}
