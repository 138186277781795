import { Region, brand } from '@eon-home/react-library';

import { Scope } from '@tools/enums';
import {
    getRegion,
    isGermanUser,
    isItalianUser,
    checkForScopes,
    isHungarianUser,
} from '@tools/utils';

interface RegionalMarketingRestrictions {
    readonly pv: boolean;
    readonly smt: boolean;
    readonly battery: boolean;
    readonly wallbox: boolean;
    readonly electricCar: boolean;
    readonly smartLights: boolean;
    readonly smartPlugs: boolean;
    readonly heatingCooling: boolean;
}

export const regionalMarketingRestrictions =
    (): RegionalMarketingRestrictions => {
        const isWhiteLabel = brand.whitelabel;

        const isFromItaly = isItalianUser();
        const isFromGermany = isGermanUser();
        const isFromHungary = isHungarianUser();

        // prettier-ignore
        return {
            pv: isWhiteLabel || isFromGermany || isFromHungary,
            // Temporarily disabled https://jira.eon.com/browse/HS-10622
            smt: true,
            battery: isWhiteLabel || isFromGermany || isFromItaly || isFromHungary,
            wallbox: false,
            electricCar: false,
            // Disable Lightwave: https://jira.eon.com/browse/HS-11275
            smartPlugs: true,
            smartLights: true,
            // smartPlugs: isWhiteLabel || isFromGermany || isFromItaly || isFromSweden || isFromHungary,
            // smartLights: isWhiteLabel || isFromGermany || isFromItaly || isFromSweden || isFromHungary,
            heatingCooling: isWhiteLabel || isFromGermany,
        };
    };

export const shouldShowWallboxMarketingWidget = (
    hasWallbox?: boolean,
): boolean => {
    const region = getRegion();
    const basicPlanEvSupportedRegions = [Region.GB, Region.SE, Region.HU];

    const hasEvScopes = checkForScopes(
        [Scope.ENERGYDEVICES_EV_READ, Scope.ENERGYDEVICES_EV_WRITE],
        true,
    );
    const hasElectricCarScopes = checkForScopes(
        [
            Scope.ENERGYDEVICES_ELECTRIC_CAR_READ,
            Scope.ENERGYDEVICES_ELECTRIC_CAR_WRITE,
        ],
        true,
    );

    if (hasElectricCarScopes) {
        return false;
    }

    return (
        (hasEvScopes && !hasWallbox) ||
        (!hasEvScopes && basicPlanEvSupportedRegions.includes(region))
    );
};

export const shouldShowEvSubscriptionPrompt = (): boolean =>
    !checkForScopes(
        [Scope.ENERGYDEVICES_EV_READ, Scope.ENERGYDEVICES_EV_WRITE],
        true,
    );

export const shouldShowElectricCarMarketingWidget = (
    isGridXCustomer: boolean,
    hasElectricCar?: boolean,
): boolean => {
    const hasElectricCarScopes = checkForScopes(
        [
            Scope.ENERGYDEVICES_ELECTRIC_CAR_READ,
            Scope.ENERGYDEVICES_ELECTRIC_CAR_WRITE,
        ],
        true,
    );

    return !isGridXCustomer && hasElectricCarScopes && !hasElectricCar;
};

export const shouldHideSpotPriceValues = (): boolean => {
    const region = getRegion();

    return [Region.DE].includes(region);
};

export const shouldShowConsentRestrictions = () => getRegion() === Region.DE;
export const shouldShowGridSupportiveControlRestrictions = () =>
    checkForScopes([Scope.GRID_SUPPORTIVE_CONTROL_READ]) &&
    getRegion() === Region.DE;
