/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  BaseAutocompletePredictionsModel,
  Forbidden,
  PlaceDetailsResult,
  Unauthorized,
} from '../models';

export interface LocationGetAddressPredictionsRequest {
    address: string;
    countryCode?: string;
}

export interface LocationGetLocationDetailsRequest {
    placeId: string;
}

/**
 * 
 */
export class LocationApi extends runtime.BaseAPI {

    /**
     * Getting list of all addresses based on the query params based on the response of google autocomplete api
     * Get list of address via google autocomplete api
     */
    async locationGetAddressPredictionsRaw(requestParameters: LocationGetAddressPredictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BaseAutocompletePredictionsModel>>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling locationGetAddressPredictions.');
        }

        const queryParameters: any = {};

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.countryCode !== undefined) {
            queryParameters['countryCode'] = requestParameters.countryCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/location/autocomplete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Getting list of all addresses based on the query params based on the response of google autocomplete api
     * Get list of address via google autocomplete api
     */
    async locationGetAddressPredictions(requestParameters: LocationGetAddressPredictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BaseAutocompletePredictionsModel>> {
        const response = await this.locationGetAddressPredictionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get location details based on placeId
     * Get location details based on placeId
     */
    async locationGetLocationDetailsRaw(requestParameters: LocationGetLocationDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlaceDetailsResult>> {
        if (requestParameters.placeId === null || requestParameters.placeId === undefined) {
            throw new runtime.RequiredError('placeId','Required parameter requestParameters.placeId was null or undefined when calling locationGetLocationDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.placeId !== undefined) {
            queryParameters['placeId'] = requestParameters.placeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/location/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get location details based on placeId
     * Get location details based on placeId
     */
    async locationGetLocationDetails(requestParameters: LocationGetLocationDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlaceDetailsResult> {
        const response = await this.locationGetLocationDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
