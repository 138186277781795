import { Dispatch } from 'redux';

import { SitesApi, ResponseError } from '@swagger-http';

import { Scope } from '@tools/enums';
import { getSites } from '@store/actions';
import {
    handleError,
    checkForScopes,
    createRequestConfiguration,
} from '@tools/utils';

const errors: Record<'start' | 'stop', string> = {
    start: 'Error when activating the session: ',
    stop: 'Error when deleting the session: ',
};

export const startPolling = (
    siteId: string,
    dispatch: Dispatch<any>,
): Promise<void | Response> => {
    if (!checkForScopes([Scope.ME_WRITE])) {
        return Promise.resolve();
    }

    if (!siteId) {
        return Promise.reject(errors.start + 'No site ID provided');
    }

    return new Promise((resolve, reject) =>
        new SitesApi(createRequestConfiguration())
            .sitesStartActivePolling({
                siteId,
            })
            .then(() => resolve())
            .catch(async (e: ResponseError) => {
                if (e?.response?.status === 404) {
                    dispatch(getSites());
                }

                await handleError(e, errors.start);

                return reject(e.response);
            }),
    );
};

export const endPolling = (
    siteId: string,
    dispatch: Dispatch<any>,
): Promise<void | Response> => {
    if (!checkForScopes([Scope.ME_WRITE])) {
        return Promise.resolve();
    }

    if (!siteId) {
        return Promise.reject(errors.stop + 'No site ID provided');
    }

    return new Promise((resolve, reject) =>
        new SitesApi(createRequestConfiguration())
            .sitesStopActivePolling({
                siteId,
            })
            .then(() => resolve())
            .catch(async (e: ResponseError) => {
                if (e?.response?.status === 404) {
                    dispatch(getSites());
                }

                await handleError(e, errors.stop);

                return reject(e.response);
            }),
    );
};
