import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { isIOS, isAndroid, isMobileOnly } from 'react-device-detect';

import { brand } from '@eon-home/react-library';

const APP_NAME = 'E.ON Home';
const THEME_COLOR = '#ea1c0a';

export const Head: FC = () => {
    const viewportMeta =
        isMobileOnly && (isIOS || isAndroid) ? (
            <meta name="viewport" content="width=device-width" />
        ) : (
            <meta name="viewport" content="width=1170, initial-scale=1" />
        );

    if (Object.keys(brand).length === 0) {
        return (
            <Helmet>
                {viewportMeta}
                <meta name="description" content={APP_NAME} />
                <meta name="apple-mobile-web-app-title" content={APP_NAME} />
                <meta name="theme-color" content={THEME_COLOR} />
                <meta name="application-name" content={APP_NAME} />
                <meta name="msapplication-TileColor" content={THEME_COLOR} />
            </Helmet>
        );
    }

    return (
        <Helmet>
            <title>{brand.title}</title>
            {viewportMeta}
            <meta name="description" content={brand.description} />
            <meta name="theme-color" content={brand.themeColor} />
            <meta name="application-name" content={brand.title} />
            <meta name="msapplication-TileColor" content={brand.themeColor} />
        </Helmet>
    );
};

export default Head;
