import { Dispatch } from 'redux';

import { Region, Locale } from '@eon-home/react-library';

import { ResponseError } from '@swagger-http';

import { ExtendedLocale } from '@tools/enums';
import { CMSDataTypes, CMSActionTypes } from '@store/enums';
import { Moment, handleError, checkForScopes } from '@tools/utils';
import {
    CMSAction,
    CMSDataModel,
    CMSSurveyModel,
    CMSResponseModel,
} from '@store/types';
import {
    CMS_LANGS,
    CMS_FAQ_URL,
    CMS_TERMS_URL,
    CMS_SALES_URL,
    CMS_NODE_BASE,
    CMS_PRIVACY_URL,
    CMS_SURVEYS_URL,
    CMS_MARKETING_URL,
    CMS_TENANT_TERMS_URL,
    // CMS_HIGHLIGHTS_URL,
    CMS_TENANT_PRIVACY_URL,
    CMS_REFERRAL_TERMS_URL,
    CMS_HEATING_INSIGHTS_URL,
    CMS_INCIDENT_MESSAGES_URL,
    CMS_CONTACT_URL,
} from '@tools/constants';
import {
    SaleCMSItem,
    StringOrNull,
    CategoryItem,
    AccordionItem,
    MarketingItem,
    MarketingSection,
} from '@tools/types';

type AsyncArrowFn<T> = (dispatch?: Dispatch) => Promise<T>;

export const cmsSetError = (type: CMSDataTypes): CMSAction => ({
    type: CMSActionTypes.ERROR,
    payload: {
        [type]: {
            data: [],
            error: true,
            loading: false,
        },
    },
});

export const cmsSetLoading = (type: CMSDataTypes): CMSAction => ({
    type: CMSActionTypes.LOADING,
    payload: {
        [type]: {
            data: [],
            error: false,
            loading: true,
        },
    },
});

export const cmsSetData = (data: any, type: CMSDataTypes): CMSAction => ({
    type: CMSActionTypes.SET_DATA,
    payload: {
        [type]: {
            data,
            error: false,
            loading: false,
        },
    },
});

export const sortCMSItems = (a: any, b: any): number =>
    (a.fields?.sortIndex || 0) - (b.fields?.sortIndex || 0);

export const filterBasedOnScopes = (item: any): boolean => {
    const requiredScopes = item.fields?.featureFlags || [];
    return requiredScopes.length === 0 ? true : checkForScopes(requiredScopes);
};

export const extractHighlights = (data: CMSDataModel[]): AccordionItem[] =>
    data
        ? data
              .sort((a: any, b: any): number => {
                  const aDate: number = new Date(a.fields.date).getTime();
                  const bDate: number = new Date(b.fields.date).getTime();

                  return bDate - aDate;
              })
              .map(
                  (item: any): AccordionItem => ({
                      title: '', // When needed, extract the title from `item.fields.title`
                      date: Moment(item.fields.date).format('MMMM DD, YYYY'),
                      content: item.fields.content,
                      slug: item.fields.slug,
                  }),
              )
        : [];

export const convertToHTML = (data: string): StringOrNull => {
    const parser: DOMParser = new DOMParser();
    const doc: Document = parser.parseFromString(data, 'text/html');

    if (!doc.body.firstChild) {
        return null;
    }

    if (doc.body.firstChild.nodeName.toLowerCase() === 'p') {
        return doc.body.firstChild.textContent;
    }

    return doc.body.innerHTML;
};

export const parseAsHTML = (markup: string): StringOrNull => {
    const parser: DOMParser = new DOMParser();
    const doc: Document = parser.parseFromString(markup, 'text/html');

    doc.querySelectorAll('a[href^="http"]:not([target])').forEach(
        (link: HTMLAnchorElement) => {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
        },
    );

    return doc.body.innerHTML;
};

export const extractFAQ = (data: CMSDataModel[]): CategoryItem[] =>
    data
        ? data
              .filter(filterBasedOnScopes)
              .sort(sortCMSItems)
              .map(
                  (item: any): CategoryItem => ({
                      title: item.fields.title,
                      slug: item.fields.slug,
                      content: parseAsHTML(item.fields.content) as string,
                      category: item.fields.category,
                      categorySlug: item.fields.categorySlug,
                  }),
              )
        : [];

export const extractContactContent = (data: CMSDataModel[]): string =>
    data
        ? (data.find((item: any) => item.fields.content)?.fields
              .content as string) || ''
        : '';

export const extractMarketingSections = (sections: any): MarketingSection[] =>
    sections
        ? sections
              .filter(
                  (section: any) =>
                      section.microschema.name === 'MarketingSection',
              )
              .map(
                  (item: any): MarketingSection => ({
                      buttonLabel: item.fields.buttonLabel,
                      buttonUrl: item.fields.buttonHyperLink,
                      title: item.fields.title,
                      content: item.fields.content,
                      frameLegend: item.fields.frameLegend,
                  }),
              )
        : [];

export const extractMarketing = (data: CMSDataModel[]): MarketingItem[] =>
    data
        ? data.sort(sortCMSItems).map(
              (item: any): MarketingItem => ({
                  type: item.fields.type,
                  sections: extractMarketingSections(
                      item.fields.marketingSections,
                  ),
                  banner: {
                      image: item.fields.backgroundImage,
                      buttonLabel: item.fields.buttonLabel,
                      buttonUrl: item.fields.buttonHyperLink,
                      content: item.fields.content,
                      title: item.fields.title,
                  },
              }),
          )
        : [];

export const extractHeatingInsights = (data: CMSDataModel[]) =>
    data.map((item: any) => ({
        buttonLabel: item.fields.buttonText,
        buttonUrl: item.fields.buttonLink,
        content: item.fields.content,
    }));

export const extractSales = (data: CMSDataModel[]): SaleCMSItem[] =>
    data
        ? data.sort(sortCMSItems).map((item: any) => ({
              id: item.uuid,
              fields: item.fields,
          }))
        : [];

export const extractSurveys = (data: CMSDataModel[]): CMSSurveyModel[] =>
    data
        ? data.map((item) => ({
              id: item.uuid,
              fields: item.fields as unknown as CMSSurveyModel['fields'],
          }))
        : [];

export const buildUrl = (url: string, lang: string) =>
    lang
        ? url + '&lang=' + CMS_LANGS[lang.toUpperCase() as ExtendedLocale]
        : url;

export const extractTermsLinks = (
    data: CMSDataModel[],
    dispatch: Dispatch,
): void => {
    dispatch({
        type: CMSActionTypes.SET_TERMS_LINKS,
        payload: {
            termsLinks: data.map(
                ({ uuid, version, language }: CMSDataModel) =>
                    `${CMS_NODE_BASE}${uuid}?version=${version}&lang=${language}`,
            ),
        },
    });
};

export const cmsGetData =
    (
        url: string,
        type: CMSDataTypes,
        lang: string | ExtendedLocale,
    ): AsyncArrowFn<string | void> =>
    async (dispatch: Dispatch<any>): Promise<string | void> => {
        await dispatch(cmsSetLoading(type));

        try {
            const content = await fetch(buildUrl(url, lang))
                .then(
                    (data: Response): Promise<CMSResponseModel> => data.json(),
                )
                .then(({ data }: { data: CMSDataModel[] }) => {
                    if (type === CMSDataTypes.HIGHLIGHTS) {
                        return extractHighlights(data);
                    }

                    if (type === CMSDataTypes.FAQ) {
                        return extractFAQ(data);
                    }
                    if (type === CMSDataTypes.CONTACT) {
                        return extractContactContent(data);
                    }

                    if (type === CMSDataTypes.MARKETING) {
                        return extractMarketing(data);
                    }

                    if (type === CMSDataTypes.SALES) {
                        return extractSales(data);
                    }

                    if (type === CMSDataTypes.ITALIAN_TERMS) {
                        extractTermsLinks(data, dispatch);
                    }

                    if (
                        type === CMSDataTypes.ITALIAN_TERMS ||
                        type === CMSDataTypes.ITALIAN_PRIVACY
                    ) {
                        return data
                            .sort(sortCMSItems)
                            .map((item: CMSDataModel) => ({
                                title: item.fields.Title,
                                content: item.fields.Content,
                            }));
                    }

                    if (type === CMSDataTypes.TERMS) {
                        extractTermsLinks(data, dispatch);
                    }

                    if (type === CMSDataTypes.HEATING_INSIGHTS) {
                        return extractHeatingInsights(data);
                    }

                    if (type === CMSDataTypes.SURVEYS) {
                        return extractSurveys(data);
                    }

                    if (type === CMSDataTypes.INCIDENT_MESSAGES) {
                        return data
                            .sort(sortCMSItems)
                            .map((item: CMSDataModel) => ({
                                id: item.uuid,
                                fields: item.fields,
                            }));
                    }

                    return convertToHTML(
                        (data?.[0]?.fields?.content as string) || '',
                    );
                });

            await dispatch(cmsSetData(content, type));
        } catch (e) {
            await dispatch(cmsSetError(type));

            await handleError(
                new ResponseError(
                    new Response('Error when fetching data from CMS.'),
                ),
                'Error when fetching data from CMS:',
            );
        }
    };

export const fetchCMSData =
    (locale: Locale, region: Region): AsyncArrowFn<string | void> =>
    async (dispatch: Dispatch<any>): Promise<string | void> => {
        // Fallback to English if the provided locale is not supported
        if (!Object.values(Locale).includes(locale)) {
            locale = Locale.EN;
        }

        // The CMS uses 'se' instead of 'sv' for Swedish language
        if (locale === Locale.SE) {
            locale = 'se' as Locale;
        }

        // Fallback to Great Britain if the provided region is not supported
        if (!Object.values(Region).includes(region)) {
            region = Region.GB;
        }

        Promise.all([
            dispatch(cmsGetData(CMS_CONTACT_URL, CMSDataTypes.CONTACT, region)),
            dispatch(cmsGetData(CMS_FAQ_URL, CMSDataTypes.FAQ, region)),
            dispatch(
                cmsGetData(CMS_MARKETING_URL, CMSDataTypes.MARKETING, region),
            ),
            dispatch(
                cmsGetData(
                    CMS_HEATING_INSIGHTS_URL,
                    CMSDataTypes.HEATING_INSIGHTS,
                    locale,
                ),
            ),
            // dispatch(
            //     cmsGetData(CMS_HIGHLIGHTS_URL, CMSDataTypes.HIGHLIGHTS, locale),
            // ),
            dispatch(
                cmsGetData(
                    CMS_REFERRAL_TERMS_URL,
                    CMSDataTypes.REFERRAL_TERMS,
                    region,
                ),
            ),
            ...(region === Region.IT
                ? [
                      dispatch(
                          cmsGetData(
                              CMS_TENANT_PRIVACY_URL,
                              CMSDataTypes.ITALIAN_PRIVACY,
                              region,
                          ),
                      ),
                      dispatch(
                          cmsGetData(
                              CMS_TENANT_TERMS_URL,
                              CMSDataTypes.ITALIAN_TERMS,
                              region,
                          ),
                      ),
                  ]
                : [
                      dispatch(
                          cmsGetData(CMS_TERMS_URL, CMSDataTypes.TERMS, region),
                      ),
                      dispatch(
                          cmsGetData(
                              CMS_PRIVACY_URL,
                              CMSDataTypes.PRIVACY,
                              region,
                          ),
                      ),
                  ]),
            dispatch(cmsGetData(CMS_SURVEYS_URL, CMSDataTypes.SURVEYS, region)),
            dispatch(
                cmsGetData(
                    CMS_INCIDENT_MESSAGES_URL,
                    CMSDataTypes.INCIDENT_MESSAGES,
                    region,
                ),
            ),
        ]);
    };

export const fetchSalesCMSData =
    (region: Region): AsyncArrowFn<string | void> =>
    async (dispatch: Dispatch<any>): Promise<string | void> => {
        dispatch(cmsGetData(CMS_SALES_URL, CMSDataTypes.SALES, region));
    };

export const fetchTermsAndConditionsVersion = async (
    region: Region,
): Promise<string> => {
    const url =
        CMS_TERMS_URL +
        '&lang=' +
        CMS_LANGS[region.toUpperCase() as ExtendedLocale];

    return await fetch(url)
        .then((data: Response): Promise<CMSResponseModel> => data.json())
        .then(({ data }: { data: CMSDataModel[] }) => data[0].version);
};
