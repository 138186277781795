import moment from 'moment-timezone';

import i18n from '@src/i18n';
import { getDefaultLocale } from '@tools/utils';

export const Moment = (
    np?: moment.MomentInput,
    format?: moment.MomentFormatSpecification,
    language?: string,
    strict?: boolean,
) => {
    // ensures the integration tests are working
    const i18nLanguage = (i18n && i18n.language) || getDefaultLocale();

    return moment(np, format, language, strict).locale(i18nLanguage);
};

export default Moment;
