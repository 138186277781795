import { EventsResponseModel } from '@swagger-sse/api';

import { LiveDataEvent } from '@store/types';
import { isSwedishUser } from '@tools/utils';
import { SmartHomeDevice } from '@store/enums';
import {
    UnusableEvent,
    WallboxEvent,
    SmartPlugEvent,
    SmartLightEvent,
    HeatingCoolingEvent,
    HeatPumpEvent,
} from '@store/actions';

export class EventsFactory {
    public getEvent(eventData: EventsResponseModel | undefined): LiveDataEvent {
        if (!eventData) {
            return new UnusableEvent();
        }

        switch (eventData.deviceType) {
            case 'EV':
                return new WallboxEvent(eventData);
            case 'switch':
            case 'lb':
                return new SmartLightEvent(eventData);
            case 'socket':
                return isSwedishUser()
                    ? new UnusableEvent()
                    : new SmartPlugEvent(eventData);
            case 'HEATING':
                return new HeatingCoolingEvent(eventData, SmartHomeDevice.TRV);
            case 'AIR_CONDITIONING':
            case 'HEATINGCOOLING':
                return new HeatingCoolingEvent(
                    eventData,
                    SmartHomeDevice.AIR_CONDITIONING,
                );
            case 'HOT_WATER':
                return new HeatingCoolingEvent(
                    eventData,
                    SmartHomeDevice.BOILER,
                );
            case 'HEAT_PUMP':
                return new HeatPumpEvent(eventData);
            default:
                return new UnusableEvent();
        }
    }
}
