export const enum LightState {
    NOT_INSTALLED = 'NotInstalled',
    ERROR = 'Error',
    INACTIVE = 'Inactive',
    LOADING = 'Loading',
    OFFLINE = 'Offline',
    OFF = '0',
    ON = '1',
}

export const enum SmartPlugState {
    NOT_INSTALLED = 'NotInstalled',
    ERROR = 'Error',
    INACTIVE = 'Inactive',
    LOADING = 'Loading',
    OFFLINE = 'Offline',
    OFF = '0',
    ON = '1',
}

export const enum SmartPlugIcon {
    EU = 'EU',
    EU_ERROR = 'EU_ERROR',
    GB = 'GB',
    GB_ERROR = 'GB_ERROR',
}

export type TileIconType =
    | 'light'
    | 'switch'
    | 'intensity'
    | 'light-error'
    | 'trashcan'
    | 'pencil'
    | 'down-arrow'
    | 'thermostat'
    | 'thermostat-error'
    | 'battery-depleted'
    | 'check-icon'
    | 'hot-water'
    | 'hot-water-error'
    | 'ac-icon'
    | 'ac-fan-low'
    | 'ac-fan-med'
    | 'ac-fan-high'
    | 'ac-fan-auto'
    | 'ac-swing'
    | 'ac-cooling'
    | 'ac-heating'
    | 'ac-pv'
    | 'ac-error';

export const enum TileColors {
    'OFF' = 'color-grey',
    'ON' = 'color-secondary',
    'WHITE' = 'color-white',
    'ACTIVE' = 'color-secondary',
    'INACTIVE' = 'color-grey',
    'GRAY' = 'color-middlegrey',
}

export const enum Vendors {
    PHILIPS = 'philips',
    LIGHTWAVE = 'lwrf',
    TADO = 'tado',
    TPLINK = 'tplink',
}

export const BRANDS: Record<string, string> = {
    [Vendors.PHILIPS]: 'Philips',
    [Vendors.TADO]: 'tado°',
    [Vendors.LIGHTWAVE]: 'LightwaveRF',
};

export const enum ConnectMode {
    ERROR = 'ERROR',
    DETAILS = 'DETAILS',
    INITIAL = 'INITIAL',
    SUCCESS = 'SUCCESS',
    SETTINGS = 'SETTINGS',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    INSTALLATION_ID = 'INSTALLATION_ID',
}

export const enum AirConditionerMode {
    DEFAULT = 'default',
    AC_MODE = 'acmode',
    FAN_SPEED = 'fanspeed',
}
