import { WeatherActionTypes } from '@store/enums';
import { WeatherState, WeatherAction } from '@store/types';

export const initialState: WeatherState = {
    current: {
        error: false,
        loading: true,
        city: '',
    },
    forecast: {
        error: false,
        loading: true,
        city: '',
        latitude: 0,
        longitude: 0,
        timezone: '',
        daily: {
            data: [],
        },
    },
};

export default function reducer(
    state: WeatherState = initialState,
    action: WeatherAction,
): WeatherState {
    switch (action.type) {
        case WeatherActionTypes.GET_CURRENT_WEATHER_DATA:
            return {
                ...state,
                current: {
                    ...state.current,
                    ...action.payload,
                },
            };

        case WeatherActionTypes.GET_WEATHER_FORECAST_DATA:
            return {
                ...state,
                forecast: {
                    ...state.forecast,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}
