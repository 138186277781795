import { ReactNode } from 'react';
import { TFunction } from 'i18next';

import { PromptProps } from '@components';
import {
    getHeating,
    getProducts,
    getHomeType,
    getWaterHeating,
    getYourPriority,
    getHomeOwnership,
    getHomeInsulation,
    getBooleanOptions,
} from './options';

export type HomeProfileFieldName =
    | 'houseType'
    | 'homeOwnership'
    | 'houseHoldSize'
    | 'bedrooms'
    | 'products'
    | 'homeHeating'
    | 'waterHeating'
    | 'hotWaterTank'
    | 'oldBoiler'
    | 'homeInsulation'
    | 'smartThermostat'
    | 'yourPriority'
    | 'marketingConsent';

export interface FormStepField {
    type: 'select' | 'radio' | 'checkbox';
    label: string;
    name: HomeProfileFieldName;
    values: any;
    hint?: string | ReactNode;
    prompt?: PromptProps;
}

export const booleanFields = [
    'hotWaterTank',
    'oldBoiler',
    'smartThermostat',
    'marketingConsent',
];

export const arrayFields = ['products', 'homeInsulation'];

export const makeSelectValueLabel = (value: number, max: number) =>
    value + 1 === max ? `${value + 1}+` : value + 1;

export const getFormFields = (t: TFunction): Array<Array<FormStepField[]>> => [
    [
        [
            {
                type: 'select',
                label: t('Home type'),
                name: 'houseType',
                values: getHomeType(t),
            },
            {
                type: 'select',
                label: t('Number of people who live in your home'),
                name: 'houseHoldSize',
                values: Array(10)
                    .fill('')
                    .map((_, index: number) => ({
                        value: index + 1,
                        text: makeSelectValueLabel(index, 10),
                    })),
            },
            {
                type: 'select',
                label: t('Number of bedrooms'),
                name: 'bedrooms',
                values: Array(5)
                    .fill('')
                    .map((_, index: number) => ({
                        value: index + 1,
                        text: makeSelectValueLabel(index, 5),
                    })),
            },
        ],
        [
            {
                type: 'radio',
                label: t('Do you rent or own your home?'),
                name: 'homeOwnership',
                values: getHomeOwnership(t),
                hint: <p>{t('(Please choose only one option)')}</p>,
            },
            {
                type: 'checkbox',
                label: t('What other energy products do you have already?'),
                name: 'products',
                values: getProducts(t),
                hint: <p>{t('(You can choose more than one option)')}</p>,
            },
        ],
    ],
    [
        [
            {
                type: 'radio',
                label: t('How do you mainly heat your home?'),
                name: 'homeHeating',
                values: getHeating(t),
                hint: <p>{t('(Please choose only one option)')}</p>,
            },
            {
                type: 'radio',
                label: t('How do you heat your water?'),
                name: 'waterHeating',
                values: getWaterHeating(t),
                hint: <p>{t('(Please choose only one option)')}</p>,
            },
            {
                type: 'radio',
                label: t('Do you have a hot water tank?'),
                name: 'hotWaterTank',
                values: getBooleanOptions(t),
                hint: <p>{t('(Please choose only one option)')}</p>,
            },
        ],
        [
            {
                type: 'radio',
                label: t('Is your boiler more than 8 years old?'),
                name: 'oldBoiler',
                values: getBooleanOptions(t),
                hint: <p>{t('(Please choose only one option)')}</p>,
                prompt: {
                    children: (
                        <p>
                            {t(
                                'If your boiler is over 8 years old then it will not be as efficient as a new one.',
                            )}
                        </p>
                    ),
                    confirmText: t('Close'),
                },
            },
            {
                type: 'checkbox',
                label: t('Is your home insulated?'),
                name: 'homeInsulation',
                values: getHomeInsulation(t),
                hint: <p>{t('(You can choose more than one option)')}</p>,
            },
            {
                type: 'radio',
                label: t('Do you have a smart thermostat?'),
                name: 'smartThermostat',
                values: getBooleanOptions(t),
                hint: <p>{t('(Please choose only one option)')}</p>,
            },
        ],
    ],
    [
        [
            {
                type: 'checkbox',
                label: t('What is important to you in respect to energy?'),
                name: 'yourPriority',
                values: getYourPriority(t),
                hint: <p>{t('(You can choose more than one option)')}</p>,
            },
        ],
        [
            {
                type: 'radio',
                label: t('Marketing consent'),
                name: 'marketingConsent',
                values: getBooleanOptions(t),
                hint: (
                    <>
                        <p>
                            {t(
                                'We want to help you save money on your bills and use energy more efficiently by using your energy and profile data.',
                            )}
                        </p>
                        <p>
                            {t(
                                'This includes within {{ APPNAME }} with product recommendations including push notifications and then using your existing marketing consent* for any correspondence outside {{ APPNAME }}.',
                            )}
                        </p>
                        <p>
                            {t(
                                'Please choose if you would like us to do this.',
                            )}
                        </p>
                    </>
                ),
            },
        ],
    ],
];
