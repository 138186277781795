// All comments based on https://jira.eon.com/browse/HS-5443

import { GridConnectionPointHistoryAggregationResponseModel as GcpModel } from '@swagger-http';

import { Moment } from '@tools/utils';
import { StringOrNull } from '@tools/types';
import {
    GridStatus,
    SolarCloudStatus,
    HistoricalResolution,
} from '@store/enums';
import {
    GenerationValue,
    HistoricalAggregationData,
    ExtendedPvAggregationModel,
} from '@store/types';

export const diff = (
    last: GenerationValue,
    first: GenerationValue,
    keys: (keyof GenerationValue)[],
): Record<string, number> => {
    const result: Record<string, number> = {};

    for (const key of keys) {
        const lastValue = last[key] || 0;
        const firstValue = first[key] || 0;

        result[key] = (lastValue as number) - (firstValue as number);
    }

    return result;
};

export const calculateGenerationConsumption = (
    pvbatteryData: ExtendedPvAggregationModel[],
    gcpData: GcpModel[],
    isSolarEdgeWithBattery: boolean,
    hasBattery: boolean = false,
    isEnergyFlow: boolean = false,
): HistoricalAggregationData => {
    const noConsumption: HistoricalAggregationData['consumption'] = {
        total: 0,
        fromPv: 0,
        fromBattery: 0,
        fromGrid: 0,
    };

    const noGeneration: HistoricalAggregationData['generation'] = {
        total: 0,
        toHome: 0,
        inBattery: 0,
        toGrid: 0,
    };

    const noPV = pvbatteryData.length === 0;
    const noGCP = gcpData.length === 0;

    if (noPV && noGCP) {
        return {
            generation: noGeneration,
            consumption: noConsumption,
        };
    }

    const lastPv = pvbatteryData[pvbatteryData.length - 1]?.values!;
    const firstPv = pvbatteryData[0]?.values!;
    const pvDiff =
        lastPv && firstPv
            ? diff(lastPv, firstPv, [
                  'PV2home',
                  'PV2homeToHome',
                  'PV2homeToGrid',
                  'PV2homeToBattery',
                  'inverterEnergyIn',
                  'inverterEnergyOut',
              ])
            : {};

    const inverterBalance = Math.max(
        0,
        pvDiff.inverterEnergyOut - pvDiff.inverterEnergyIn,
    );

    if (noGCP) {
        if (isEnergyFlow) {
            return {
                generation: noPV
                    ? noGeneration
                    : {
                          total: pvDiff.PV2home,
                          toHome: pvDiff.PV2home,
                          inBattery: pvDiff.PV2homeToBattery,
                          toGrid: 0,
                      },
                consumption: noConsumption,
            };
        }

        return {
            generation: noPV
                ? noGeneration
                : {
                      total: pvDiff.PV2home,
                      toHome: pvDiff.PV2homeToHome,
                      inBattery: pvDiff.PV2homeToBattery,
                      toGrid: pvDiff.PV2homeToGrid,
                  },
            consumption: noConsumption,
        };
    }

    const lastGcp = gcpData[gcpData.length - 1].values!;
    const firstGcp = gcpData[0].values!;

    const gcpDiff = diff(lastGcp, firstGcp, [
        'energyGrid2home',
        'energyHome2grid',
        'energyConsumption',
        'energySelfConsumption',
        'energyConsumptionFromPV',
        'energyConsumptionFromGrid',
        'energyConsumptionFromBattery',
    ]);

    if (isSolarEdgeWithBattery) {
        return {
            generation: noPV
                ? noGeneration
                : {
                      total: inverterBalance,
                      toHome: gcpDiff.energySelfConsumption,
                      inBattery: pvDiff.PV2homeToBattery,
                      toGrid: gcpDiff.energyHome2grid,
                  },
            consumption: {
                total: gcpDiff.energyConsumption,
                fromPv: gcpDiff.energySelfConsumption,
                fromBattery: gcpDiff.energyConsumptionFromBattery,
                fromGrid: gcpDiff.energyGrid2home,
            },
        };
    }

    if (isEnergyFlow) {
        return {
            generation: noPV
                ? { ...noGeneration, toGrid: gcpDiff.energyHome2grid }
                : {
                      total: pvDiff.PV2home,
                      toHome: hasBattery
                          ? pvDiff.inverterEnergyOut
                          : pvDiff.PV2home,
                      inBattery: pvDiff.inverterEnergyIn,
                      toGrid: gcpDiff.energyHome2grid,
                  },
            consumption: {
                total: gcpDiff.energyConsumption,
                fromPv: gcpDiff.energyConsumptionFromPV,
                fromBattery: gcpDiff.energyConsumptionFromBattery,
                fromGrid: gcpDiff.energyGrid2home,
            },
        };
    }

    return {
        generation: noPV
            ? noGeneration
            : {
                  total: pvDiff.PV2home,
                  /*
                    TODO: Re-enable this when the on-demand calculations for KiwiGrid
                    are fixed on the backend. Related ticket: https://jira.eon.com/browse/HS-6806
                  */
                  // toHome: pvDiff.PV2homeToHome,
                  toHome: gcpDiff.energyConsumptionFromPV,
                  inBattery: pvDiff.PV2homeToBattery,
                  toGrid: pvDiff.PV2homeToGrid,
              },
        consumption: {
            total: gcpDiff.energyConsumption,
            fromPv: gcpDiff.energyConsumptionFromPV,
            fromBattery: gcpDiff.energyConsumptionFromBattery,
            fromGrid: gcpDiff.energyConsumptionFromGrid,
        },
    };
};

export const slice = <T>(collection: T[], length: number): T[] =>
    collection.slice(length);

export const checkProductActivationForPeriod = (
    solarCloud: Record<
        'solarCloudStartDate' | 'solarCloudEndDate',
        StringOrNull
    >,
    startDate: string,
    endDate: string,
): SolarCloudStatus => {
    const { ACTIVE, INACTIVE, MIXED } = SolarCloudStatus;
    const chartPeriodEnd = Moment(endDate).endOf('day').valueOf();
    const chartPeriodStart = Moment(startDate).endOf('day').valueOf();
    const solarCloudStart = Moment(solarCloud.solarCloudStartDate)
        .endOf('day')
        .valueOf();

    if (!solarCloud.solarCloudStartDate && !solarCloud.solarCloudEndDate) {
        return INACTIVE;
    }

    // Case 1: product without end date
    if (!solarCloud.solarCloudEndDate) {
        const isInChartRange =
            chartPeriodStart < solarCloudStart &&
            solarCloudStart <= chartPeriodEnd;
        const isActive = solarCloudStart <= chartPeriodStart;

        return isInChartRange ? MIXED : isActive ? ACTIVE : INACTIVE;
    }

    // Case 2: product with end date
    // {@link: https://stackoverflow.com/a/325964/2765346}
    const solarCloudEnd = Moment(solarCloud.solarCloudEndDate)
        .endOf('day')
        .valueOf();

    // prettier-ignore
    const isOverlapped = solarCloudStart <= chartPeriodEnd && chartPeriodStart <= solarCloudEnd;

    if (!isOverlapped) {
        return INACTIVE;
    }

    // prettier-ignore
    const isPartiallyOverlapped = chartPeriodStart < solarCloudStart || solarCloudEnd < chartPeriodEnd;

    return isPartiallyOverlapped ? MIXED : ACTIVE;
};

export const getGridStatus = (power: number): GridStatus => {
    if (power === 0) {
        return GridStatus.BALANCED;
    }

    return power < 0 ? GridStatus.EXPORTING : GridStatus.IMPORTING;
};

export const smartMeterDataUnavailable = (
    input: any,
    res: HistoricalResolution,
    key: string = 'exported',
): boolean => {
    if (!input || Object.keys(input).length === 0 || !input[key]) {
        return false;
    }

    return !input[key][res].data.find((item: any) => item.value !== null);
};
