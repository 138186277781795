import { EmobilityDeviceState } from '@store/types';

export const initialState: EmobilityDeviceState = {
    error: false,
    isGaro: false,
    loading: false,
    wallbox: undefined,
    chargingModes: {
        data: [],
        error: false,
        loading: true,
    },
    chargingHistory: {
        data: null,
        error: false,
        loading: true,
    },
    firmware: {
        updateSettings: {
            error: false,
            data: { auto: false },
        },
    },
};
