/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  Forbidden,
  GvsUserInfoResponseModel,
  NotFound,
  Unauthorized,
} from '../models';

/**
 * 
 */
export class GVSApi extends runtime.BaseAPI {

    /**
     * Acknowledge GVS redemption
     */
    async gvsAcknowledgeRedemptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/gvs/acknowledge-redemption`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Acknowledge GVS redemption
     */
    async gvsAcknowledgeRedemption(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.gvsAcknowledgeRedemptionRaw(initOverrides);
    }

    /**
     * End GVS journey
     */
    async gvsEndGvsJourneyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/gvs/end-gvs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * End GVS journey
     */
    async gvsEndGvsJourney(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.gvsEndGvsJourneyRaw(initOverrides);
    }

    /**
     * Get GVS information for user
     */
    async gvsGetGvsInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GvsUserInfoResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["policy/hems/user:read"]);
        }

        const response = await this.request({
            path: `/api/v1/gvs/user-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get GVS information for user
     */
    async gvsGetGvsInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GvsUserInfoResponseModel> {
        const response = await this.gvsGetGvsInfoRaw(initOverrides);
        return await response.value();
    }

}
