import moment from 'moment';
import { GraphTypes } from '@tools/enums';
import {
    AggregatedDataState,
    AggregatedDataBranch,
    AggregatedDataAction,
} from './types';
import {
    HistoricalDataActionTypes,
    AggregatedDataActionTypes,
    HistoricalResolution as Res,
} from '@store/enums';

export const getInitialStateItem = (
    res: Res,
    type: GraphTypes,
): AggregatedDataBranch => ({
    res,
    type,
    data: {},
    error: false,
    loading: false,
    lastReading: '',
});

export const initialState: AggregatedDataState = {
    ...Object.keys(GraphTypes).reduce((result: any, type: GraphTypes) => {
        result[type] = {
            [Res.DAY15MIN]: getInitialStateItem(Res.DAY15MIN, type),
            [Res.DAY1HOUR]: getInitialStateItem(Res.DAY1HOUR, type),
            [Res.WEEK]: getInitialStateItem(Res.WEEK, type),
            [Res.MONTH]: getInitialStateItem(Res.MONTH, type),
            [Res.YEAR]: getInitialStateItem(Res.YEAR, type),
        };

        return result;
    }, {}),
    all: {
        error: false,
        loading: false,
    },
    donuts: {
        error: false,
        loading: false,
        shouldRender: true,
    },
    selectedRes: Res.DAY1HOUR,
    selectedDate: moment().toDate(),
    sliderTouched: false,
    selectedSliderValue: null,
    sliderScrollPosition: 0,
};

export default function reducer(
    state: AggregatedDataState = initialState,
    action: AggregatedDataAction,
): AggregatedDataState {
    if (!action.payload) {
        return state;
    }

    const { res, type: graphType } = action.payload;
    const type = graphType as string;

    switch (action.type) {
        case AggregatedDataActionTypes.LOADING:
            return {
                ...state,
                ...(type === 'all'
                    ? Object.keys(GraphTypes).reduce(
                          (result: any, t: GraphTypes) => {
                              if (!res) {
                                  return result;
                              }

                              result[t] = {
                                  ...state[t],
                                  [res]: {
                                      ...state[t][res],
                                      error: false,
                                      loading: true,
                                  },
                              };

                              return result;
                          },
                          {},
                      )
                    : {
                          [type]: {
                              ...state[type],
                              ...(res
                                  ? {
                                        [res]: {
                                            ...state[type][res],
                                            error: false,
                                            loading: true,
                                        },
                                    }
                                  : {}),
                          },
                      }),
            };
        case AggregatedDataActionTypes.ERROR:
            return {
                ...state,
                ...(type === 'all' || type === 'donuts'
                    ? Object.keys(GraphTypes).reduce(
                          (result: any, t: GraphTypes) => {
                              if (!res) {
                                  return result;
                              }

                              result[t] = {
                                  ...state[t],
                                  [res]: {
                                      ...state[t][res],
                                      error: true,
                                      loading: false,
                                  },
                              };

                              return result;
                          },
                          {},
                      )
                    : {
                          [type]: {
                              ...state[type],
                              ...(res
                                  ? {
                                        [res]: {
                                            ...state[type][res],
                                            error: true,
                                            loading: false,
                                        },
                                    }
                                  : {}),
                          },
                      }),
            };
        case AggregatedDataActionTypes.FETCH:
            return {
                ...state,
                ...(type === 'all'
                    ? Object.keys(GraphTypes).reduce(
                          (result: any, t: GraphTypes) => {
                              if (!res) {
                                  return result;
                              }

                              result[t] = {
                                  ...state[t],
                                  [res]: {
                                      ...action.payload,
                                      error: false,
                                      loading: false,
                                  },
                              };

                              return result;
                          },
                          {},
                      )
                    : {
                          [type]: {
                              ...state[type],
                              ...(res
                                  ? {
                                        [res]: {
                                            ...action.payload,
                                            error: false,
                                            loading: false,
                                        },
                                    }
                                  : {}),
                          },
                      }),
            };
        case AggregatedDataActionTypes.SET_DATE:
            return {
                ...state,
                selectedDate: action.payload as Date,
            };
        case AggregatedDataActionTypes.SET_RESOLUTION: {
            return {
                ...state,
                selectedRes: action.payload as unknown as Res,
            };
        }
        case AggregatedDataActionTypes.SET_SLIDER_TOUCHED:
            const sliderTouched = (action.payload as any).sliderTouched;

            return {
                ...state,
                sliderTouched,
            };
        case AggregatedDataActionTypes.SET_SLIDER_SCROLL_POSITION:
            const sliderScrollPosition = (action.payload as any).scrollPosition;

            return {
                ...state,
                sliderScrollPosition,
            };
        case AggregatedDataActionTypes.SET_SLIDER_VALUE:
            const selectedSliderValue = (action.payload as any).value;

            return {
                ...state,
                selectedSliderValue,
            };

        case HistoricalDataActionTypes.CLEAR_CACHE:
            return initialState;
        default:
            return state;
    }
}
