export const enum PowerUnit {
    'Wh' = 'Wh',
    'kWh' = 'kWh',
    'W' = 'W',
    'kW' = 'kW',
    'mW' = 'mW',
}

export const enum FormattingClauses {
    'LESS_THAN' = '<',
    'GREATER_THAN' = '<',
    'EQUAL_TO' = '==',
    'LESS_EQUAL_THAN' = '<=',
    'GREATER_EQUAL_THAN' = '>=',
}
