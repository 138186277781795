import { FC, PropsWithChildren } from 'react';

import { Scope } from '@tools/enums';
import { checkForScopes } from '@tools/utils';

interface Props {
    requiredScopes: Scope[];
}

export const WithScopes: FC<Readonly<PropsWithChildren<Props>>> = ({
    children,
    requiredScopes,
}) =>
    // eslint-disable-next-line
    checkForScopes(requiredScopes) ? <>{children}</> : null;
