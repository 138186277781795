import { EventsResponseModel } from '@swagger-sse/api';

import { SmartPlugState } from '@tools/enums';
import { LiveDataEvent, LiveDataAction } from '@store/types';
import { LiveDataActionTypes, SmartHomeDevice } from '@store/enums';

export class SmartPlugEvent implements LiveDataEvent {
    private eventData: EventsResponseModel;

    constructor(data: EventsResponseModel) {
        this.eventData = data;
    }

    public getAction(): LiveDataAction {
        const smartPlugData = this.eventData.data;

        if (!smartPlugData) {
            return {
                type: LiveDataActionTypes.SMART_PLUGS_EVENT,
                payload: null,
            };
        }

        const deviceId = this.eventData.deviceId;

        if (typeof smartPlugData.switch !== 'undefined') {
            const state: SmartPlugState =
                smartPlugData.switch === 1
                    ? SmartPlugState.ON
                    : SmartPlugState.OFF;

            return {
                type: LiveDataActionTypes.SMART_PLUGS_EVENT,
                payload: {
                    deviceId,
                    deviceType: SmartHomeDevice.SMART_PLUG,
                    state,
                    loaded: true,
                },
            };
        }

        if (typeof smartPlugData.outletInUse !== 'undefined') {
            const outletInUse = smartPlugData.outletInUse;

            return {
                type: LiveDataActionTypes.SMART_PLUGS_EVENT,
                payload: {
                    deviceId,
                    deviceType: SmartHomeDevice.SMART_PLUG,
                    outletInUse,
                    loaded: true,
                },
            };
        }

        return {
            type: LiveDataActionTypes.SMART_PLUGS_EVENT,
            payload: null,
        };
    }
}
